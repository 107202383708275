.loader {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: #ffd702;
    z-index: 11;
  }
  
  .loader video {
    margin: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
    /* background-color: rgba(0, 0, 0, 0.8); */
    width: 100%;
    height: 26%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* cursor: none; */
    z-index: -1;
  }
  