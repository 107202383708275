.swaymsevak-box {
  margin: 4% 0% 4% 0%;
}
.SwayamSevak {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 30px;
  background-color: #ffffff;
}
.SwayamSevak h3 {
  color: #ae0015;
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  line-height: 40px;
}
.SwayamSevak label {
  font-size: 15px;
  font-weight: 600;
  line-height: 28px;
  margin: 0 0 7px;
}
.SwayamSevak .checkbox-boxes-seva {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.swaymsevak-radio {
  display: flex;
  gap: 25px;
  align-items: center;
}

.swaymsevak-radio input[type='radio'] {
  accent-color: #ae0015 !important;
  width: 18px;
  margin-right: 5px;
  height: 18px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.swaymsevak-radio-main {
  display: flex;
  gap: 25px;
  align-items: center;
}

.swaymsevak-radio-main input[type='radio'] {
  accent-color: #ae0015 !important;
  width: 18px;
  margin-right: 5px;
  height: 18px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.swaymsevak-radio-check label {
  font-size: 15px;
  font-weight: 500;
  line-height: 28px;
  margin: 0 0 5px;
}
.swaymsevak-text p {
  font-size: 15px;
  font-weight: 500;
  line-height: 28px;
  margin: 0 0 5px;
}
.swaymsevak-radio-check input[type='checkbox'] {
  accent-color: #ae0015 !important;
  width: 18px;
  margin-right: 5px;
  height: 18px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.SwayamSevak-table .table-responsive {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}
.SwayamSevak-table .table-responsive th {
  text-align: center;
  padding: 8px;
  border: 1px solid #ddd;
}
.SwayamSevak-table .table-responsive td {
  text-align: center;
  padding: 8px;
  border: 1px solid #ddd;
}

@media only screen and (max-width: 767px) {
  .SwayamSevak {
    padding: 15px;
  }
  .SwayamSevak h3 {
    color: #ae0015;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    line-height: 30px;
    margin: 0px 0px 13px;
  }
  .swaymsevak-radio-main {
    display: block;
  }
  .SwayamSevak label {
    font-size: 14px;
    font-weight: 600;
    line-height: 28px;
    margin: 0 0 3px;
  }
  .swaymsevak-radio-check label {
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    margin: 0 0 5px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1440px) {
  .SwayamSevak-table .table-responsive td {
    text-align: center;
    padding: 5px;
    border: 1px solid #ddd;
    font-size: 13px;
  }
  .SwayamSevak-table .table-responsive td .form-group .form-control {
    font-size: 13px;
  }
  .SwayamSevak label {
    font-size: 13px;
    font-weight: 600;
    line-height: 26px;
    margin: 0 0 3px;
  }
  .swaymsevak-radio-check label {
    font-size: 13px;
    font-weight: 500;
    line-height: 26px;
    margin: 0 0 3px;
  }
  .swaymsevak-text p {
    font-size: 13px;
    font-weight: 500;
    line-height: 26px;
    margin: 0 0 3px;
  }
}
