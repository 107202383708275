.terms-conditions {
  margin: 4% 0%;
}
.terms-text {
  padding: 0px 0px 10px;
}
.terms-text h2 {
  color: #ae0015;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin: 2px 0px;
}
.terms-text p {
  color: #000;
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
  margin: 0px 0px;
}

.header-banner .header-heading h3 {
  position: relative;
  text-align: center;
  color: #fff;
  z-index: 1;
}
