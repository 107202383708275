.galleryMobile {
  width: 100%;
}

.galleryMobile .swiper {
  width: 100%;
  max-width: 40rem;
  height: 30rem;
}

.galleryMobile .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.galleryMobile .swiper-slide img {
  display: block;
  height: 100%;
  width: 80%;
  object-fit: cover;
}

.galleryMobile .swiper-button-next,
.galleryMobile .swiper-button-prev {
  object-fit: contain;
  height: 3.5rem !important;
  width: 3.5rem !important;
  border-radius: 50%;

  &::after {
    content: "" !important;
    background-repeat: no-repeat;
    background-size: contain;

    height: 1.5rem !important;
    width: 1.5rem !important;
  }
}

.galleryMobile .swiper-button-next::after {
  background-image: url("../Assets/Images/E_store/icon-next.svg");
  background-position: 3px;
}

.galleryMobile .swiper-button-prev::after {
  background-image: url("../Assets/Images/E_store/icon-previous.svg");
  background-position: 1px;
}

.galleryDesktop {
  width: 90%;
  margin: 0 auto;

  &__main-pic {
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
    }
  }

  &__thumbnail-box {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;

    @media screen and (min-width: 1100px) {
      gap: 2rem;
      margin-top: 2rem;
    }
  }

  &__thumbnail {
    border-radius: 10px;
    overflow: hidden;
    border: 2px solid transparent;
    cursor: pointer;
    transition: 0.5s;

    img {
      transition: 0.5s;
    }

    &:hover {
      img {
        opacity: 0.7;
      }
    }

    &--active {
      border: 2px solid #ae0015;

      img {
        opacity: 0.5;
      }

      &:hover {
        img {
          opacity: 0.5;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
