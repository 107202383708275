.Location-banner {
  background-image: url('../../../Assets/Images/Bhadarvi_poonam/location-map.jpg');
}
.Location {
  margin: 4% 0%;
}

.tabboxes-location {
  margin: 10px 0px 0px;
  padding: 15px;
  background-color: #fff;
}
.Location-content .heading-text {
  margin-bottom: 10px;
}
.Location-content .heading-text a p {
  background-color: #ae0015;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 10px;
  color: #fff;
  margin: 0px 0px;
  border-radius: 65px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.Location-content .heading-text a p p {
  flex: 1;
  padding: 0px;
}
.Location-content .heading-text a span {
  background-color: #ffffff;
  border-radius: 50px;
  color: #ae0015;
  font-size: 20px;
  width: 40px;
  height: 40px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Location .tab-btn-location .btn-act-location {
  color: #000;
  border-radius: 50px 50px;
  margin: 2px 5px;
  font-size: 17px;
  line-height: 25px;
  border: 2px solid #ae0015;
  font-weight: 700;
  padding: 10px 20px;
}

.Location .tab-btn-location .btn-active-location {
  background: #ae0015;
  color: #ffffff;
  border: 2px solid #ae0015;
  border-radius: 50px 50px;
  margin: 2px 5px;
  font-size: 17px;
  line-height: 25px;
  font-weight: 700;
  padding: 10px 20px;
}
.servicebox-bhadarvi h4 {
  font-weight: bold;
  color: #ae0015;
  text-transform: capitalize;
  margin: 5px 0px 0px;
  font-size: 17px;
  padding-top: 5px;
}

.servicebox-bhadarvi {
  background-color: #ffffff;
  margin-bottom: 24px;
  border-radius: 10px;
  padding: 25px;
  min-height: 85%;
}
.servicebox-bhadarvi:hover {
  color: #ae0015;
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.4);
}

.servicebox-bhadarvi .service-icon {
  font-size: 60px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.servicebox-bhadarvi .service-icon img {
  filter: brightness(1);
  width: 60px;
  height: 60px;
}
.servicebox-bhadarvi:hover img {
  filter: brightness(1);
}

@media only screen and (max-width: 767px) {
  .tabboxes-location {
    padding: 15px 0px;
  }
  .Location-content .heading-text a p {
    .tabboxes-location {
      margin: 10px 0px 0px;
      padding: 15px 0px 0px !important;
      background-color: #fff;
    }
    font-size: 14px;
    padding: 8px 10px;
    border-radius: 8px;
    gap: 7px;
  }
  .Location .tab-btn-location .btn-active-location {
    font-size: 14px;
    line-height: 25px;
    padding: 8px 15px;
  }
  .Location .tab-btn-location .btn-act-location {
    font-size: 14px;
    line-height: 25px;
    padding: 8px 15px;
  }
}
