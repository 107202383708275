@import '../../Css/variable.scss';

.temple-banner {
  background-image: url('../../Assets/Images/Temple/templebg.jpg');
}

//   .temple-section {
//     margin-top: 6%;
//   }

.about-page {
  margin: 4% 0%;
}
.side-pd-top {
  padding: 4% 0% 2%;
}
.zoomicon {
  background-color: #000000;
  height: 36px;
  width: 36px;
  display: inline-block;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 7px 0 0;
  text-align: center;
  transition: 0.5s all;
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  opacity: 0;
  visibility: visible;
}

.about-gallery .about-img .zoomicon img {
  background-color: #000000;
  height: 20px;
  width: 20px;
  display: inline-block;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0px 0 0;
  text-align: center;
  transition: 0.5s all;
  cursor: pointer;
  color: #ffffff;
  opacity: 0;
  visibility: visible;
}

.about-gallery .about-img:hover .zoomicon img {
  opacity: 1;
  visibility: visible;
}

.templetop h2 {
  font-size: 24px !important;
  padding-bottom: 0px !important;
  margin-bottom: 20px !important;
}

// new css for Responsive

@media only screen and (max-width: 991px) {
  .templetop h2 {
    font-size: 24px;
    padding-bottom: 5px;
    margin: 5px;
  }
}

@media only screen and (max-width: 768px) {
  .templetop h2 {
    font-size: 24px;
    padding-bottom: 0px;
  }
}

.temple .ReactModal__Content .swiper img {
  margin: auto !important;
  display: block !important;
}
.ReactModal__Content .swiper .swiper-slide img {
  min-width: 100%;
  min-height: auto;
  object-fit: cover;
}

.ReactModal__Content div.close-btn button {
  position: absolute;
  top: 10px;
  color: #ae0015;
  z-index: 11111;
  font-size: 30px;
  height: 45px;
  line-height: 30px;
  width: 45px;
  border: none;
  border-radius: 0px !important;
}
.ReactModal__Content div.close-btn button:first-child:active {
  color: #ae0015;
  border: none;
  border-radius: 0px !important;
}

.white-space {
  white-space: pre-wrap;
}

//   ------
.templetop {
  padding-bottom: 35px;
  h2 {
    font-size: 24px;
    padding-bottom: 20px;
  }
}
.templeimg {
  position: relative;
  // padding-right: 74px;
  @media only screen and (max-width: 991px) {
    max-width: 500px;
    margin: 0 auto 50px;
  }
  img {
    width: 100%;
    border-radius: 15px;
  }
  .subimage {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    max-width: 150px;
    border: 4px solid $white;
  }
}
.templetop p {
  font-size: 14px;
  line-height: 26px;
}

.about-gallery {
  margin: 15px 0px !important;
  .about-img {
    overflow: hidden;
    position: relative;
    margin: 10px 0px;
    &:hover .zoomicon {
      opacity: 1;
      visibility: visible;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .zoomicon {
      background-color: $black;
      height: 36px;
      width: 36px;
      display: inline-block;
      border-radius: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 7px 0 0;
      text-align: center;
      transition: 0.5s all;
      cursor: pointer;
      color: $white;
      font-size: 18px;
      opacity: 0;
      visibility: visible;
    }
  }
}

.about-gallery .about-img .zoomicon img {
  background-color: #000000;
  height: 20px;
  width: 20px;
  display: inline-block;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0px 0 0;
  text-align: center;
  transition: 0.5s all;
  cursor: pointer;
  color: #ffffff;
  opacity: 0;
  visibility: visible;
}
.about-gallery .about-img:hover .zoomicon img {
  opacity: 1;
  visibility: visible;
}

.about-nav ul li a img {
  font-size: 20px;
  margin-right: 8px;
  filter: brightness(0);
  height: 25px;
  width: 25px;
}
.about-nav ul li:hover img {
  filter: brightness(1);
}

@media only screen and (max-width: 767px) {
  .templeimg .subimage {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    max-width: 115px;
    border: 4px solid #ffd801;
  }
  .templeimg {
    max-width: 500px;
    margin: 0 auto 40px;
  }
  .templetop h2 {
    font-size: 24px;
    padding-bottom: 0px;
    margin: 0px 0px 14px;
  }
  .templetop {
    padding-bottom: 12px;
  }
  .temple-section {
    margin-top: 0%;
  }
  // section {
  //   padding: 20px 0;
  // }
  .about-main .about-tabs p {
    font-size: 16px;
    line-height: 26px;
  }
  .templetop p {
    font-size: 16px;
    line-height: 26px;
  }
  .about-main .about-nav {
    padding-bottom: 15px;
  }
  .about-main .about-tabs h2 {
    font-size: 18px;
    line-height: 33px;
  }
  .about-nav ul li:not(:last-child) {
    margin-right: 10px;
  }

  .leader-heading h2 {
    margin: 0px 0px 20px 0px;
  }
}

@media only screen and (max-width: 1365px) {
  .templetop p {
    font-size: 13px;
    line-height: 22px;
  }
  .about-main .about-tabs p {
    font-size: 13px;
    line-height: 22px;
  }
}
