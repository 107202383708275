@import "./variable.scss";
.attractions-page {
  margin: 4% 0%;
  .blogbox {
    margin-bottom: 25px;
  }
  .contactmap iframe {
    height: 435px;
  }
}
.gold-main {
  display: flex;
  flex-wrap: wrap;
  background-color: $white;
  margin-bottom: 80px;
  .gold-image {
    width: 420px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .gold-content {
    width: calc(100% - 420px);
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 20px 20px 20px 40px;
    ul {
      font-weight: bold;
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}
.process-main {
  background: $white;
  border: 1px solid $gray;
  border-radius: 8px;
  padding: 25px;
  display: flex;
  flex-wrap: wrap;
  .process-image {
    width: 380px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .process-content {
    width: calc(100% - 380px);
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 30px;
    h5 {
      margin-bottom: 15px;
    }
    p a {
      font-weight: bold;
      word-break: break-all;
    }
  }
}
.alternate-section {
  background-color: $primary;
  color: $white;
  font-size: 14px;
  h3 {
    color: $white;
    font-size: 20px;
  }
  ul {
    margin-bottom: 0;
    li {
      margin-bottom: 5px;
    }
  }
  a {
    color: $white;
  }
}
.alternate-box {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(253, 245, 245, 0.53);
  border-radius: 8px;
  padding: 25px 27px;

  h2 {
    color: $white;
    font-size: 20px;
    margin: 0 0 15px;
    line-height: 1.5;
  }
  p {
    font-size: 16px;
    span {
      display: block;
    }
  }
}
.shaktipith-popup {
  background: rgb(0 0 0 / 98%);
  border-radius: 8px;
  padding: 80px 30px 40px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  top: 110%;
  opacity: 0;
  transition: 0.5s all;

  .close-popup {
    position: absolute;
    width: 52px;
    height: 52px;
    right: 10px;
    top: 10px;
    background: $primary;
    border-radius: 100%;
    cursor: pointer;
    &:before {
      content: "";
      width: 24px;
      height: 4px;
      background: $white;
      border-radius: 3px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    &:after {
      content: "";
      width: 24px;
      height: 4px;
      background: $white;
      border-radius: 3px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    overflow-y: auto;
    gap: 20px 0px;
    li {
      width: 33%;
      height: 100%;
      margin-bottom: 0px;
      position: relative;
      background: rgba(255, 255, 255, 0.15);
      border: 1px solid rgba(255, 255, 255, 0.26);
      box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 10px 8px 10px 70px;
      counter-increment: ol-counter;

      @media only screen and (max-width: 991px) {
        width: 49%;
      }
      @media only screen and (max-width: 767px) {
        width: 100%;
        padding: 6px 6px 6px 45px;
      }
      &:before {
        content: counter(ol-counter);
        position: absolute;
        width: 40px;
        height: 40px;
        background: rgba(176, 20, 50, 0.59);
        border-radius: 100px;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-weight: 700;
        font-size: 16px;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        @media only screen and (max-width: 767px) {
          width: 30px;
          height: 30px;
          left: 10px;
        }
      }
      .liimg {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .licontent {
        padding-left: 15px;
        width: calc(100% - 50px);
        h4 {
          margin: 0 0 2px;
          color: $white;
          font-size: 14px;
        }
        p {
          font-size: 14px;
          color: $white;
          margin: 0px;
        }
      }
    }
  }
}

.shaktipith-popup-active {
  top: 0;
  opacity: 1;
}
.shaktipith-wrapper {
  border: 1px solid $gray;
  border-radius: 8px;
  background-color: $white;
  position: relative;
  //   min-height: 580px;
  overflow: hidden;
  &.open-popup {
    .shaktipith-popup {
      opacity: 1;
      top: 0;
    }
  }
  .shaktipith {
    &-img img {
      border-radius: 8px;
    }
    &-content {
      padding: 30px 30px 30px 0;
      h2 {
        font-size: 24px;
        margin: 0 0 5px;
      }
      h4 {
        font-weight: bold;
      }
      .heading {
        border-bottom: 1px solid $gray;
        margin-bottom: 20px;
        position: relative;
        padding-right: 60px;
      }
    }
  }
}
.detail-box {
  display: flex;
  flex-wrap: wrap;
  background-color: $white;
  .detail-image {
    width: 406px;
    display: flex;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .detail-content {
    border-left: 1px solid $gray;
    width: calc(100% - 406px);
    padding: 30px;
    font-size: 14px;
    h3 {
      border-bottom: 1px solid $gray;
      padding-bottom: 20px;
      color: $primary;
    }
  }
}
.shaktipeeth-toggle {
  width: 52px;
  height: 52px;
  background: $primary;
  border-radius: 100%;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  &:hover {
    background-color: $secondary;
  }
  span {
    width: 17px;
    height: 4px;
    background: $white;
    border-radius: 3px;
    display: inline-block;
    position: absolute;
    right: 16px;
    &:before {
      content: "";
      width: 20px;
      height: 4px;
      background: $white;
      border-radius: 3px;
      position: absolute;
      right: 0;
      top: -8px;
    }
    &:after {
      content: "";
      width: 15px;
      height: 4px;
      background: $white;
      border-radius: 3px;
      position: absolute;
      right: 0;
      bottom: -8px;
    }
  }
}
@media only screen and (max-width: 991px) {
  .detail-box {
    max-width: 500px;
    margin: 0 auto;
    .detail-content,
    .detail-image {
      width: 100%;
    }
  }
  .shaktipith-wrapper {
    max-width: 700px;
    margin: 0 auto;
    .shaktipith-img img {
      width: 100%;
    }
    .shaktipith-content {
      padding: 30px;
      font-size: 14px;
    }
  }
}
@media only screen and (max-width: 767px) {
  .gold-main .gold-content {
    width: 100%;
    padding: 30px;
  }
  .process-main .process-content {
    width: 100%;
    padding: 30px 0 0;
  }
  .alternate-box {
    margin-top: 30px;
  }
}
