.booking-form .react-datepicker__view-calendar-icon input {
  padding: 7px 10px 7px 35px;
  height: 40px;
  width: 90%;
  box-shadow: none;
}
.booking-form .react-datepicker__view-calendar-icon input:focus {
  box-shadow: none;
}
.booking-form .react-datepicker__view-calendar-icon input:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

.booking-form h6 {
  margin-right: 10px;
  margin-bottom: 0px;
  line-height: 28px;
}
@media screen and (max-width: 920px) {
  .booking-form .react-datepicker__view-calendar-icon input {
    padding: 3px 10px 3px 30px;
    height: 35px;
    width: 100%;
    box-shadow: none;
  }
}
