@media print {
  body {
    background-color: white !important;
  }
}
@page {
  size: A4;
  margin: 0;
}
table {
  margin: 0 auto;
}

.email-container-box {
  background: #fff;
  padding: 10px 15px;
  background-image: url(https://storage.googleapis.com/ambaji-temple.appspot.com/1715936839975_Ambajimandirbackgroundimgforreceipt.png);
  background-size: 50% auto;
  background-position: right 5% bottom;
  background-repeat: no-repeat;
}
.header-boeres .logo {
  width: 100px;
  height: 100px;
  margin-right: 8px;
}
.header-boeres .logo img {
  width: 100%;
  height: auto;
}
.header-boeres h1 {
  font-size: 17px;
  margin: 0px 0px;
  line-height: 27px;
  color: #a30018;
}
.header-boeres p {
  font-size: 13px;
  margin: 0px;
  line-height: 23px;
  color: #333;
}

.headig-text-mon h2 {
  font-size: 17px;
  padding: 10px 0px;
  color: #a30018;
  margin: 0px 0px;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}
.teg-line-mon p {
  font-size: 13px;
  margin: 2px 0px;
  line-height: 23px;
  color: #333;
}

.date-section-box-mon {
  margin: 2px 0px 2px !important;
  font-size: 13px;
  line-height: 23px;
  color: #333;
}

.rs-box-mon {
  border: 2px solid #a30018;
  overflow: hidden;
  height: 39px;
  width: 180px !important;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.rs-box-mon p {
  font-size: 15px;
  margin: 0px;
  line-height: 24px;
  color: #333;
  font-weight: 700;
}

.rs-box-mon p span {
  background-color: #a30018;
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  margin: -1px;
  padding: 15px;
  margin-right: 10px;
}
.rs-box-mon-text p {
  font-size: 13px;
  margin: 2px 3px;
  line-height: 23px;
  color: #333;
}
.rs-note-mon p {
  font-size: 13px;
  margin: 0px 0px;
  line-height: 23px;
  color: #333;
}
.rs-note-mon p span {
  font-weight: 700;
  font-size: 13px;
  line-height: 23px;
  margin: 9px 4px;
}
.sign-trust-mon p {
  padding: 8px 20px;
  margin: 2px 0px;
  font-size: 13px;
  line-height: 24px;
  border: 2px solid #f4f4f4;
  color: #333;
}
.sign-vahivat-mon {
  padding-top: 5px;
}
.sign-vahivat-mon img {
  width: 80px;
  height: auto;
}
.sign-vahivat-mon p {
  margin: 8px 0px 4px;
  font-size: 13px;
  line-height: 23px;
  color: #333;
  font-weight: 600;
}
.border-dest {
  display: flex;
  align-items: center;
  color: #cbcbcb;
  width: 100%;
  justify-content: center;
}

// ----------------------------mobile-------------------------//

.email-container-boxers {
  background: #fff;
  padding: 10px 15px;
  background-image: url(https://storage.googleapis.com/ambaji-temple.appspot.com/1715936839975_Ambajimandirbackgroundimgforreceipt.png);
  background-size: 50% auto;
  background-position: right 5% bottom;
  background-repeat: no-repeat;
}
.header-hed .logo-eng {
  width: 100px;
  height: 100px;
  margin-right: 8px;
}
.header-hed .logo-eng img {
  width: 100%;
  height: auto;
}
.header-hed h1 {
  font-size: 17px;
  margin: 0px 0px;
  line-height: 27px;
  color: #a30018;
}
.header-hed p {
  font-size: 13px;
  margin: 0px;
  line-height: 23px;
  color: #333;
}

.headig-text-mon-desige h2 {
  font-size: 17px;
  padding: 10px 0px;
  color: #a30018;
  margin: 0px 0px;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}
.teg-line-mon-hed p {
  font-size: 13px;
  margin: 2px 0px;
  line-height: 23px;
  color: #333;
}

.date-section-sec {
  margin: 2px 0px 2px !important;
  font-size: 13px;
  line-height: 23px;
  color: #333;
}

.rs-box-mon-section {
  border: 2px solid #a30018;
  overflow: hidden;
  height: 40px;
  width: 180px !important;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  margin-top: 12px;
}

.rs-box-mon-section p {
  font-size: 15px;
  margin: 0px;
  line-height: 24px;
  color: #333;
  font-weight: 700;
}

.rs-box-mon-section p span {
  background-color: #a30018;
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  margin: -1px;
  padding: 15px;
  margin-right: 10px;
}
.rs-box-mon-section-text p {
  font-size: 13px;
  margin: 2px 3px;
  line-height: 23px;
  color: #333;
}
.rs-notees-mon p {
  font-size: 13px;
  margin: 0px 0px;
  line-height: 23px;
  color: #333;
}
.rs-notees-mon p span {
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
  margin: 11px 5px;
}
.sign-trust-mones p {
  padding: 5px 10px;
  margin: 7px 0px 0px;
  font-size: 13px;
  line-height: 21px;
  border: 2px solid #f4f4f4;
  color: #333;
}
.sign-vahivat-mon-box p {
  margin: 11px 0px;
  font-size: 13px;
  line-height: 23px;
  color: #333;
  font-weight: 600;
}
.border-dest-tex {
  display: flex;
  align-items: center;
  color: #cbcbcb;
  width: 100%;
  justify-content: center;
}

.border-dest-tex1 {
  display: flex;
  align-items: center;
  color: #cbcbcb;
  width: 100%;
  justify-content: center;
}
.border-dest-tex2 {
  display: flex;
  align-items: center;
  color: #cbcbcb;
  width: 100%;
  justify-content: center;
}
.table-1-box {
  margin: 0 !important;
}
