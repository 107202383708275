.shaktipith-banner {
  background-image: url('../../../Assets/Images/Temple/templebg.jpg');
}
.shaktipith {
  margin: 4% 0%;
}

.tab-btn .btn-act {
  color: #000;
  font-size: 15px;
  line-height: 25px;
  font-weight: 700;
  padding: 10px 20px;
}
.tabboxes {
  padding: 15px;
  background-color: #fff;
}
.shaktipith-img {
  text-align: center;
}
.shaktipith-img img {
  width: 100%;
  background-position: center center;
}
.shaktipith-content .heading h2 {
  color: #ae0015;
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  margin: 0px;
}
.shaktipith-content .heading h4 {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  margin: 15px 0px 20px;
}
.shaktipith-content p {
  font-size: 14px;
  line-height: 26px;
  white-space: break-spaces;
  height: 360px;
  overflow-y: auto;
  margin: 0;
}
.shaktipith .tab-btn .btn-act {
  color: #000;
  border-radius: 50px 50px;
  margin: 2px 5px;
  border: 2px solid #ae0015;
}

.shaktipith .tab-btn .btn-active {
  background: #ae0015;
  color: #ffffff;
  border: 2px solid #ae0015;
  border-radius: 50px 50px;
  margin: 2px 5px;
  font-size: 15px;
  line-height: 25px;
  font-weight: 700;
  padding: 10px 20px;
}
