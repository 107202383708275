.error_404,
.error_500 {
  padding: 150px 0px 70px 0px;
  text-align: center;
}

.error_404 .ambaji-temple-image {
  max-width: 300px;
}

.error_404 .heading {
  color: #ae0015;
  margin-top: 20px;
}
