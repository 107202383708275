@import '../../Css/variable.scss';

.window_height {
  // display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.mainbanner {
  height: 100vh;
  overflow: hidden;
  padding: 0;
  position: relative;
}
.bannerslider {
  height: 100%;
  position: relative;
}

.mainbanner .banner-item {
  position: relative;
  height: 100%;
  &:before {
    content: '';
    // background: linear-gradient(
    //   0deg,
    //   rgba(0, 0, 0, 0.3) 0%,
    //   rgba(0, 0, 0, 0) 50.06%,
    //   rgba(0, 0, 0, 0.6) 100%
    // );

    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
.bannerimg {
  height: 100%;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.bannercontent {
  position: absolute;
  bottom: 10%;
  left: 0;
  width: 100%;
  z-index: 2;
  h1 {
    color: $white;
    margin: 0 0 0px;
  }
}
.contentinner {
  max-width: 580px;
  margin-left: 70px;
}
.aboutrow {
  &:not(:last-child) {
    margin-bottom: 60px;
  }
  &:nth-child(even) {
    .row {
      flex-flow: row-reverse;
      .aboutimg {
        padding: 0 0 0 30px;
      }
    }
  }
  .aboutimg {
    position: relative;
    margin-bottom: 5px;
    img {
      // border-radius: 10px;
      // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      // width: 100%;
      // position: absolute;
      // height: 95%;
      // object-fit: cover;
      // top: 0;
      // left: 0;
      border-radius: 10px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      width: 100%;
      position: relative;
      height: 260px;
      object-fit: cover;
      /* top: 0; */
      /* left: 0; */
    }
  }
  .aboutcontent {
    padding: 0px 0px;
    h2 {
      margin-bottom: 15px;
      font-size: 21px;
      margin-top: 20px;
    }
    p {
      font-size: 14px;
      line-height: 27px;
      padding-bottom: 15px;
    }
  }
}
.aboutbg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  // padding: 130px 0;
  .about-wrapper {
    h2,
    p {
      color: $white;
      line-height: 28px;
    }
  }
}

.todaydate {
  width: 100%;
  background-color: $primary;
  padding: 15px 20px;
  color: $white;
  border-radius: 10px;
  display: flex;
  margin-bottom: 5px;
  .templeimg {
    width: 80px;
    height: 80px;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    padding: 10px;
    color: $gray-dark;
    font-size: 50px;
    max-width: inherit;
    margin: 0;
  }
  .date {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.2;
    // width: calc(100% - 80px);
    padding-left: 15px;
    span {
      display: block;
      font-size: 16px;
    }
  }
}

.monthname {
  font-size: 32px;
  line-height: 1;
}
.eventbox {
  padding: 18px 20px 18px 120px;
  background: $white;
  border-radius: 8px;
  margin-bottom: 20px;
  position: relative;
  min-height: 100px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  &:hover {
    box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.4);
  }
  .event-timeicon {
    font-size: 42px;
    border-right: 1px solid $secondary-light;
    width: 110px;
    text-align: center;
    position: absolute;
    left: 0;
    top: 50%;
    line-height: 1;
    transform: translateY(-50%);
  }
  h5 {
    position: relative;
    color: inherit;
    padding: 5px 0;
    margin: 0;
  }
  p {
    margin: 0;
    margin: 7px 0px;
    font-size: 14px;
  }
  &:hover {
    color: $primary;
  }
}
.upcoming-events {
  padding: 80px 0 0;
}
.eventtimes {
  background: $white;
  border: 1px solid $gray;
  border-radius: 8px;
  padding: 15px 15px 15px 90px;
  position: relative;
  margin-bottom: 30px;

  &:hover {
    color: $primary;
    box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.4);
  }
  .postdate {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    font-weight: bold;
    border-right: 1px solid $gray;
    padding-right: 10px;
    span {
      display: block;
      font-size: 14px;
      font-weight: 600;
    }
  }
  h4 {
    margin: 0 0 10px;
    font-weight: bold;
    text-transform: capitalize;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      position: relative;
      font-size: 14px;
      padding: 0 0 4px 19px;
      i {
        position: absolute;
        left: 0;
        top: 1px;
        font-size: 16px;
        &.iicon-icon-15 {
          font-weight: bold;
        }
      }
    }
  }
}

.eventpost {
  position: relative;
  display: flex;
  padding-top: 100%;
  align-items: flex-end;
  margin: 0px;
  margin-bottom: 30px;
  border-radius: 10px;
  &:hover {
    box-shadow: 0 0 7px 0px rgba(0, 0, 0, 0.7);
    border-radius: 10px;
  }
  .eventimg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    overflow: hidden;
    &:before {
      content: '';
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.01) 0%,
        rgba(0, 0, 0, 0.5) 100%
      );
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
  .eventcontent {
    position: absolute;
    bottom: 0;
    padding: 0 18px 20px;
    left: 0;
    right: 0;
    z-index: 1;
    color: $white;
    h3 {
      color: inherit;
      margin: 0 0 4px;
      font-size: 20px;
    }
    p {
      color: inherit;
      margin: 0;
      font-size: 14px;
    }
  }
}
.about-section {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}
.about-section:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: $red;
  opacity: 0.4;
}
.about-wrapper {
  position: relative;
  z-index: 1;
}
.about-content {
  color: $white;
  max-width: 700px;
}
.about-content h2 {
  color: $white;
}
.service-section {
  padding: 4% 0px;
  h2 {
    margin: 0 0 20px;
  }
}
.service-top {
  // padding: 50px 0 0 0px;
}
.footerbox {
  // border: 1px solid #e5e3e3;
  background-color: #ffffff;
  padding: 25px 25px 25px 115px;
  border-radius: 10px;
  position: relative;
  margin-bottom: 20px;
  height: calc(100% - 25px);

  &:hover {
    box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.4);
    .footericon {
      color: $primary;
    }
  }
  .footericon {
    position: absolute;
    top: 43%;
    left: 25px;
    width: 70px;
    transform: translateY(-50%);
    font-size: 30px;
    line-height: 0;
    text-align: center;
  }
  p {
    font-size: 15px;
    margin: 0 0px;
    line-height: 30px;
  }
}
// .service-wrapper {
// }
.servicebox {
  background-color: $white;
  margin-bottom: 24px;
  border-radius: 10px;
  padding: 25px;
  min-height: 90%;
  &:hover {
    color: $primary;
    box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.4);
  }
  .service-icon {
    font-size: 60px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .iicon-icon-11 {
      font-weight: bold;
    }
  }
  p {
    font-size: 14px;
    color: $gray-dark;
    margin: 0px;
  }
  .ancorlink {
    border-bottom: 1px solid $primary;
    line-height: 1;
    display: inline-block;
    font-weight: 600;
  }
}
.serviceicon {
  width: 80px;
  height: 80px;
  margin: 0 0 25px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  padding: 10px;
  color: $primary;
  font-size: 50px;
}
.serviceicon img {
  filter: invert(1);
}
.society-section {
  background-repeat: no-repeat;
  background-size: cover;
  &.overlay:before {
    opacity: 0.9;
  }
}
.society-wrapper {
  position: relative;
  z-index: 1;
  h2 {
    margin-bottom: 40px;
    color: $white;
    span {
      color: $secondary;
    }
  }
}
.shop-wrapper {
  padding-bottom: 20px;
}
.societybox {
  border-radius: 10px;
  background-color: $white;
  overflow: hidden;
  margin-bottom: 20px;
  &:hover {
    box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.4);
    h5 {
      color: $primary;
    }
    .orangebtn {
      background-color: $white;
      color: $primary;
    }
  }
  .societybox-img {
    height: 280px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      position: relative;
      object-fit: cover;
      background-size: cover;
      background-position: center center;
    }
  }
  .societybox-img-shop {
    height: 380px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      position: relative;
      object-fit: cover;
      background-size: cover;
      background-position: center center;
    }
  }
  h5 {
    color: $gray-dark;
  }
}

.progressamunt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
}
.progressbar {
  height: 7px;
  background-color: #d7d7d7;
  margin-bottom: 13px;
  position: relative;
}
.progressbar span {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: $yellow;
  left: 0;
  transition: 0.5s all;
}
.societybox-body {
  padding: 10px 10px 20px 10px;
}
.shop-section {
  margin-top: 0px;
  h2 {
    margin-bottom: 40px;
  }
}
// .temple-section {
// }
.temple-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .temple-image {
    width: 40%;
    position: relative;
    > img {
      position: relative;
      z-index: 0;
    }
  }
  .temple-content {
    width: 60%;
    padding-left: 30px;
  }
}
.headingdiv {
  position: absolute;
  width: 192px;
  height: 192px;
  background-color: #ae0015;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  flex-flow: column;
  line-height: 1.2;
  right: 14%;
  z-index: -1;
  top: -25px;
}
.headingdiv span {
  display: block;
  font-size: 14px;
  font-weight: 500;
  padding-top: 2px;
}
.praydiv {
  display: flex;
  border-radius: 10px;
  font-weight: bold;
  color: $gray-dark;
  align-items: center;
  i {
    font-size: 54px;
    margin-right: 5px;
  }
}
.templeicon {
  height: 78px;
  width: 78px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #ae0015;
  background-color: #ffffff;
  position: absolute;
  right: 8%;
  top: 0%;
}
.temple-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.temple-phone {
  display: flex;
  flex-flow: column;
  background-color: $primary;
  color: $white;
  padding: 30px 30px;
  position: relative;
  width: calc(40% - 40px);
  align-items: flex-end;
  justify-content: center;
}
.temple-phone:before {
  content: '';
  position: absolute;
  right: 100%;
  top: 0;
  bottom: 0;
  background-color: $primary;
  width: 100%;
}
.temple-info p {
  margin: 0 0 5px;
  color: #ffffd6;
}
.temple-info h2 {
  margin: 0;
  color: $white;
}
.ordiv {
  font-size: 16px;
  color: $black;
  font-weight: 600;
  width: 40px;
  text-align: center;
  padding: 10px;
  display: flex;
  align-items: center;
}
.temple-address {
  display: flex;
  flex-flow: column;
  background-color: $primary;
  color: $white;
  padding: 30px 30px;
  position: relative;
  width: 60%;
  justify-content: center;
}
.temple-address:before {
  content: '';
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  background-color: $primary;
  width: 100%;
}
.leader-section {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 10px 0px 4%;
  &:before {
    content: '';
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.6;
  }
}
.leader-wrapper {
  position: relative;
  z-index: 1;
}
.leader-heading {
  color: #000;
  max-width: 770px;
  position: relative;
  z-index: 1;
  h2 {
    color: #000;
  }
}
.leaderbox {
  text-align: center;
}
.leaderbox-img {
  width: 100%;
  border-radius: 100%;
  img {
    height: 300px;
    width: 100%;
    object-fit: cover;
    border-radius: 0.375rem 0.375rem 0px 0px;
  }
}

.leader-wrapper .card {
  border: none !important;
  position: relative;
  min-height: 97%;
  margin: 0px 0px 20px 0px;
}
.leaderbox-body h5 {
  position: relative;
  padding-bottom: 10px;
  color: #000;
  &:before {
    content: '';
    width: 104px;
    height: 2px;
    background: #ae0015;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
.leaderbox-body {
  padding: 30px 10px;
  text-align: center;
  p {
    color: #000;
    font-size: 14px;
    margin: 0 auto;
  }
}
.prashad-section {
  background-color: $white;
  .prashadcontent {
    .serviceicon {
      border: 2px solid $primary;
    }
    h2 {
      margin-bottom: 15px;
    }
  }
}

.temples__section-2 .swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: var(--swiper-pagination-bottom, -12px);
  top: var(--swiper-pagination-top, auto);
  left: 0;
  width: 100%;
}

// .blog-section {
// }
.blogbox {
  margin-bottom: 20px;

  &:hover {
    .blogbox-img {
      box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.4);
    }
    .blogbox-body {
      h5 {
        color: $primary;
      }
    }
  }
  &-img {
    width: 100%;
    height: 300px;
    overflow: hidden;
    border-radius: 8px;
    img {
      width: 100%;
      height: 100%;
      position: relative;
      object-fit: cover;
      background-size: cover;
      background-position: center center;
    }
  }
  &-body {
    max-width: 90%;
    margin: 0px auto 0;
    background-color: $white;
    position: relative;
    padding: 20px;
    box-shadow: 0 0 25px 3px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-block-start: -25px;
    margin-block-end: 0;
    p {
      margin: 0 0 5px;
      font-size: 14px;
    }
    h5 {
      margin: 0;
      color: $black;
    }
  }
}

.blog-wrapper {
  margin-bottom: 20px;
}
.reach-wrapper {
  position: relative;
  z-index: 1;
}
.reach-section {
  h2 {
    color: $white;
    span {
      color: $secondary;
    }
  }
}
.reachrow {
  padding: 25px 25px;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  min-height: 110px;
}
.reachbox {
  position: relative;
  padding: 12px 0 12px 90px;
  // &:last-child {
  //   padding-bottom: 0;
  // }
  &:hover {
    .reach-icon {
      background-color: $primary;
      color: $white;
    }
  }
  .reach-icon {
    width: 75px;
    height: 75px;
    background-color: $white;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    position: absolute;
    left: 0;
    top: 7px;
    &.iicon-icon-7 {
      font-weight: bold;
    }
  }
  p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1200px) {
  .headingdiv {
    top: -9%;
  }
  .templeicon {
    right: 8%;
    top: 8%;
  }
  .temple-wrapper {
    .temple-image {
      width: 100%;
      max-width: 560px;
      margin: 0 auto 50px;
    }
    .temple-content {
      width: 100%;
      padding-left: 0;
      text-align: center;
      margin-bottom: 20px;
    }
    .praydiv {
      justify-content: center;
    }
  }
  .schedule-wrapper {
    padding: 0;
  }
  .schedule-content {
    width: calc(100% - 150px);
  }
}
@media only screen and (max-width: 991px) {
  .service-wrapper {
    .service-content {
      width: 100%;
      padding: 0 0 40px;
      text-align: center;
      .serviceicon {
        margin: 0 auto 20px;
      }
    }
    .service-list {
      width: 100%;
    }
  }
  .service-top {
    padding: 20px 0 0;
  }
}
@media only screen and (max-width: 767px) {
  .societybox {
    margin-bottom: 30px;
  }
  .donation-btn .orange-whitebtn {
    margin: 0 10px 10px;
  }
  .aboutrow {
    &:nth-child(even) .row {
      flex-flow: column;
    }
  }

  // .about-home,
  // .aboutbg {
  //   padding: 60px 0;
  // }
  .about-wrapper {
    text-align: center;
    .btn-group {
      flex-flow: column;
      align-items: center;
      .white-btn.ms-4 {
        margin: 10px 0 0 !important;
      }
    }
  }
  .schedule-content {
    width: 100%;
    padding: 0;
  }
  // .todaydate {
  //   max-width: 300px;
  //   margin: 0 auto 50px;
  // }
  .reachrow {
    padding: 20px 15px;
  }
  .reachbox {
    position: relative;
    padding: 0 0 20px 65px;
    .reach-icon {
      width: 50px;
      height: 50px;
      font-size: 30px;
    }
  }
}
@media only screen and (max-width: 575px) {
  .headingdiv {
    top: 0;
    right: 40px;
  }
  .templeicon {
    top: 50px;
    right: 0;
  }
  .temple-image {
    padding-top: 90px;
  }
}

.Parikrama-gabber {
  background-image: url('../../Assets/Images/Gabbar/Gabbar-images.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.library-bg {
  background-image: url('https://storage.googleapis.com/ambaji-temple.appspot.com/1710754159016_MicrosoftTeams-image(14).png');
  background-repeat: no-repeat;
  background-size: cover;
}

.parikrama-bg {
  background-image: url('https://storage.googleapis.com/ambaji-temple.appspot.com/1692249591479_Parikrama.jpg');
}
.society-bg {
  background-image: url('../../Assets/Images/Donation/AmbajiTemple_Donation.png');
}

.Sangh-bg {
  background-image: url('../../Assets/Images/Bhadarvi_poonam/ambaji-Sangh_e.jpg');
}
.seva-camp-bg {
  background-image: url('../../Assets/Images/Bhadarvi_poonam/ambaji-Seva.jpg');
}

.reach-bg {
  background-image: url('../../Assets/Images/leaderimg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

@media only screen and (max-width: 1200px) {
  .center {
    // height: auto;
    // height: 50%;
    // padding: 60px 0;
    align-items: center;
    justify-content: center;
  }
}

.footer .whatsapp-icon {
  position: fixed !important;
  bottom: 5%;
  left: 3%;
  width: 50px;
  height: 50px;
  background-color: white !important;
  color: black !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  box-shadow: 0px 0px 5px #dbdadb !important;
  z-index: 100;
}

.footer .whatsapp-icon a {
  text-decoration: none;
  color: #fff !important;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whatsapp-link {
  background-color: white;
  width: 70px;
  height: 70px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.whatsapp-icon {
  position: fixed;
  bottom: 25px;
  left: 22px;
  z-index: 888;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
}

//
.stacked-social-icons {
  position: fixed;
  bottom: 60px;
  left: 22px;
  z-index: 888;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 0;
}

.social-icons {
  position: absolute;
  bottom: 20px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.social-icons.expanded {
  opacity: 1;
  visibility: visible;
}

.social-icons a {
  margin-bottom: 0px;
  color: #333;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  padding: 5px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-icons a img {
  height: 40px;
  width: 40px;
}

.social-icons a.twitter-icon {
  height: 40px;
  width: 40px;
  background-color: #fff;
  border-radius: 50%;
}

.social-icons a.twitter-icon img {
  padding: 4px;
  object-fit: contain;
}

.previous-button {
  background-color: $primary !important;
  color: $white !important;
}
.upcoming-button {
  border-color: $primary !important;
  color: $white !important;
}

// .swiper .swiper-pagination-tooltip {
//   display: none;
//   position: absolute;
//   bottom: 125%;
//   left: 50%;
//   transform: translateX(-50%);
//   background-color: rgba(0, 0, 0, 0.7);
//   color: #fff;
//   padding: 4px 8px;
//   border-radius: 4px;
//   font-size: 14px;
// }

// .swiper-vertical
//   .swiper-pagination
//   .swiper-pagination-bullet-active
//   .swiper-pagination-tooltip {
//   display: block;
// }

.swiper-pagination-bullet {
  position: relative !important;
  height: 11px !important;
  width: 11px !important;
  background-color: #ae0015 !important;
  margin: 20px 5px !important;
}
.prasango .swiper-pagination-bullet {
  position: relative !important;
  // display: block !important;
  height: 10px !important;
  width: 10px !important;
  background-color: #ae0015 !important;
  margin: -10px 5px !important;
}

.tooltip-title {
  position: absolute;
  right: 20px;
  /* left: -105px; */
  top: -10px;
  color: #ae0015;
  min-width: max-content;
  background-color: #fff;
  padding: 3px 7px;
  border-radius: 30px;
  font-size: 14px;
  opacity: 0;
}

.swiper-pagination-bullet.custom-tooltip:hover .tooltip-title {
  opacity: 1;
}
// .swiper-pagination-vertical.swiper-pagination-bullets,
// .swiper-vertical > .swiper-pagination-bullets {
//   right: var(--swiper-pagination-right, 100%);
//   left: var(--swiper-pagination-left, 30px);
//   top: 50%;
//   transform: translate3d(0px, -50%, 0);
// }

.custom-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.custom-tooltip::after {
  content: attr(title);
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  padding: 5px;
  background-color: #333;
  color: #fff;
  white-space: nowrap;
  opacity: 0 !important;
  pointer-events: none;
  transition: opacity 0.2s ease;
}

.custom-tooltip:hover::after {
  opacity: 1;
}
.read-more {
  margin-top: 20px;
}
.combined-section {
  margin-top: 3%;
}

// for video streaming

.video-streaming {
  width: 100%;
  height: 100vh;
}
.scroll-top-btn button {
  text-decoration: none;
  color: #fff !important;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-top-btn {
  position: fixed;
  bottom: 12%;
  right: 10px;
  width: 50px;
  height: 50px;
  background-color: $primary;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0px 0px 5px #a98153;
  z-index: 100;
}

// new css for css new added

// new css for css new added

.prashadimg img {
  width: 100%;
  position: relative;
  display: inline-block !important;
}
// .prashadimg-1 img{width: 100%;position: relative; display:none !important;}

.stacked-social-icons-1 {
  position: fixed;
  bottom: 60px;
  right: 22px;
  z-index: 888;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 0;
}
.whatsapp-icon-1 {
  position: fixed;
  bottom: 25px;
  right: 22px;
  z-index: 888;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
}

// .aboutrow .aboutcontent p {
//   height: 160px;
// }
.todaydate {
  align-items: center;
}

.eventbox .event-timeicon img {
  filter: brightness(0);
  height: 50px;
  width: 50px;
}
.eventbox:hover img {
  filter: brightness(1);
}
.servicebox .service-icon img {
  filter: brightness(0);
  height: 65px;
  width: 65px;
}
.servicebox:hover img {
  filter: brightness(1);
}

.footerbox:hover img {
  filter: brightness(1);
}

.iicon-icon-all {
  position: relative;
  display: inline-block;
  overflow: hidden;
  line-height: 0px;
  width: 45px;
}
.iicon-icon-all .img-top {
  display: none;
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 99;
}
.reachbox:hover .reach-icon .img-top {
  display: inline;
  background-color: #ae0015;
}
.heading-bhadarvi {
  width: 100%;
  // margin: auto 10%;
}

.app .wrapper .mainbanner .banner-item {
  position: relative;
  min-height: 100vh;
}

.FooterHight {
  // height: 100vh;
  background-color: $primary;
}

// .aboutrow {
//   max-height: 70vh;
// }
// .about-wrapper {
//   max-height: 70vh;
// }
// .max-heighest {
//   max-height: 70vh;
// }
// .society-wrapper {
//   max-height: 70vh;
// }
// .prashad-wrapper {
//   max-height: 70vh;
// }
.max-heighest h2 {
  margin-bottom: 8px;
  font-size: 27px;
  margin-top: 8px;
}
.societybox {
  margin: 0px 0px 25px 0px;
  min-height: 96%;
}

.max-heighest h2 span {
  font-size: 27px;
}
.service-section h2 {
  font-size: 27px;
  margin: 10px 0 10px;
}
.societybox-body h5 {
  margin: 15px 0px 12px;
}
.reach-section h2 {
  color: #ffffff;
  margin: 0px 0px 20px;
  font-size: 27px;
  line-height: 32px;
}
.temple-wrapper .temple-image {
  width: 420px;
  height: auto;
}
.combined-section {
  margin-top: 5%;
}

.blog-wrapper {
  margin-bottom: 5px;
}

.footerbox .footericon img {
  filter: brightness(0);
  width: 80px;
}

.societybox-body p {
  font-size: 14px;
  margin-bottom: 12px;
  color: #333333;
}
.aboutbg .about-wrapper h2 {
  color: #ffffff;
  font-size: 27px;
  font-weight: bold;
  margin: 0 0 30px;
}
.eventbox {
  margin-bottom: 5px;
}
.heading-textes {
  width: 80%;
}
// new css for Responsive
@media only screen and (max-width: 1365px) {
  // .aboutrow {
  //   max-height: 65vh;
  // }
  // .about-wrapper {
  //   max-height: 65vh;
  // }
  // .max-heighest {
  //   max-height: 65vh;
  // }
  // .society-wrapper {
  //   max-height: 65vh;
  // }
  // .prashad-wrapper {
  //   max-height: 65vh;
  // }
  .aboutrow .aboutcontent p {
    font-size: 13px;
    line-height: 22px;
    padding-bottom: 15px;
  }

  .aboutrow .aboutimg {
    // padding-top: 212px;
    position: relative;
    margin-bottom: 25px;
  }
  .aboutbg .about-wrapper p {
    color: #ffffff;
    font-size: 13px;
    line-height: 22px;
  }

  .aboutbg .about-wrapper h2 {
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    margin: 0px 0 20px;
  }
  .eventbox .event-timeicon {
    border-right: 1px solid #ffcc89;
    width: 100px;
  }
  .eventbox .event-timeicon img {
    filter: brightness(0);
    height: 45px;
    width: 45px;
  }

  .service-section h2 {
    font-size: 20px;
    margin: 3px 0 5px;
  }
  .max-heighest h2 span {
    font-size: 20px;
  }
  .footerbox {
    padding: 25px 25px 25px 115px;
    border-radius: 10px;
    position: relative;
    margin-bottom: 20px;
    height: calc(100% - 25px);
  }
  .footerbox p {
    font-size: 13px;
    line-height: 22px;
    margin: 0 0 5px;
  }
  .servicebox {
    background-color: #ffffff;
    margin-bottom: 24px;
    border-radius: 10px;
    padding: 25px;
    min-height: 90%;
  }

  .servicebox .service-icon img {
    filter: brightness(0);
    height: 50px;
    width: 50px;
  }
  .servicebox .service-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  .servicebox p {
    font-size: 13px;
    color: #333333;
    line-height: 22px;
  }
  .societybox-body p {
    font-size: 13px;
    margin-bottom: 10px;
    color: #333333;
  }
  .societybox-body {
    padding: 10px;
  }

  .prashad-wrapper .prashadcontent h2 {
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 15px;
  }
  .prashad-wrapper .prashadcontent p {
    font-size: 13px;
    line-height: 22px;
  }
  .orangebtn {
    background-color: #ae0015;
    border: 2px solid #ae0015;
    font-size: 14px;
    padding: 7px 17px;
  }
  .max-heighest h2 {
    margin-bottom: 15px;
    font-size: 20px;
  }
  .reach-section h2 {
    color: #ffffff;
    margin: 0px 0px 17px;
    font-size: 20px;
    line-height: 32px;
  }
  .reachbox p {
    font-size: 13px;
    line-height: 22px;
  }
  .temple-wrapper .temple-content h2 {
    color: #000000;
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 20px;
  }
  .praydiv {
    font-size: 13px;
    align-items: center;
  }
  .praydiv img {
    height: auto;
    width: 40px;
    margin-right: 5px;
  }
  .reachbox {
    position: relative;
    padding: 0 0 22px 100px;
  }
  .temple-wrapper .temple-image {
    width: 400px;
    height: auto;
  }
  .headingdiv {
    position: absolute;
    line-height: 1;
    right: 15%;
    top: -15px;
    z-index: -1;
  }
  .headingdiv span {
    display: block;
    font-size: 13px;
    font-weight: 600;
    padding-top: 11px;
  }
  .templeicon {
    height: 78px;
    width: 78px;
    right: 5%;
    top: 12%;
  }
  .templetop p {
    font-size: 13px;
    line-height: 24px;
    margin: 0px;
  }
  .templetop {
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 1120px) {
  .center {
    align-items: center;
    justify-content: center;
    padding: 50px 0px;
    height: auto !important;
  }

  .center {
    align-items: center;
    justify-content: center;
    padding: 50px 15px;
    min-height: 100vh;
  }
  .todaydate .date {
    font-size: 21px;
  }
}
@media only screen and (max-width: 1024px) {
  .todaydate .date {
    font-size: 21px;
  }
  .eventbox {
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  .todaydate {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .todaydate .date {
    font-size: 27px;
  }
}
@media only screen and (max-width: 768px) {
  .bannercontent h1 {
    color: #ffffff;
    font-size: 50px;
    margin: 0px 24px -30px;
    margin-left: 40px;
  }
  .center {
    align-items: center;
    justify-content: center;
    padding: 40px 0px;
    height: auto !important;
  }
  .aboutrow .aboutcontent p {
    height: auto;
    padding: 0px 0px;
  }
}
@media only screen and (max-width: 768px) {
  header {
    padding: 15px 0;
  }

  .mainbanner .banner-item:before {
    content: '';
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0) 50.06%,
      rgba(0, 0, 0, 0.6) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    width: 100%;
  }
  .swiper-vertical {
    touch-action: pan-x;
    height: auto !important;
  }

  .mainbanner {
    width: 100%;
    max-height: 300px !important;
    overflow: hidden;
    padding: 0;
    position: relative;
  }

  .header-logo {
    width: 60px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%) !important;
  }

  .read-more {
    margin-top: 20px;
  }

  .center {
    align-items: center;
    justify-content: center;
    padding: 30px 0px;
    min-height: 100vh;
  }
  .servicebox {
    background-color: #ffffff;
    padding: 25px;
    height: auto;
  }
  .todaydate {
    margin: 0 auto 5px;
  }
  .todaydate .date {
    font-size: 25px;
    padding-left: 15px;
  }
  .todaydate {
    padding: 15px 15px;
  }
  .eventbox {
    padding: 5px 20px 5px 125px;
    background: #ffffff;
    border-radius: 8px;
    margin: 10px 0px;
    position: relative;
    min-height: 100px;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
  .aboutbg .about-wrapper h2 {
    font-size: 25px;
    margin-bottom: 15px;
    line-height: 45px;
  }
  .aboutbg .about-wrapper p {
    font-size: 16px;
    line-height: 28px;
  }
  .eventbox p {
    margin: 0;
    font-size: 15px;
    line-height: 27px;
  }
  .heading-textes {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1500px) {
  .iicon-icon-all-1 {
    position: relative;
    display: inline-block;
    overflow: hidden;
    line-height: 10px;
  }

  .iicon-icon-all-1 img {
    width: 45px;
    height: 45px;
    line-height: 1px;
  }

  .iicon-icon-all-1 .img-top {
    display: none;
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 99;
  }
  .reachbox:hover .reach-icon .img-top {
    display: inline;
    background-color: #ae0015;
    border-radius: 5%;
  }

  .mainbanner .bannerslider .banner-item .bannercontent {
    position: absolute;
    // bottom: 5%;
    left: 0;
    width: 100%;
    z-index: 2;
  }

  .mainbanner .bannerslider .banner-item .bannercontent .contentinner {
    max-width: 450px;
    margin-left: 0px;
  }

  .mainbanner .bannerslider .banner-item .bannercontent .contentinner h1 {
    font-size: 40px;
    line-height: 50px;
    margin-left: 70px;
  }
  .whatsapp-icon-1 {
    bottom: 30px;
    position: fixed;
    right: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .social-icons {
    position: absolute;
    bottom: 85px;
    left: -10px;
  }
  .social-icons a {
    margin-bottom: 0px;
  }
  .whatsapp-icon {
    position: fixed;
    bottom: 20px;
    left: 10px;
  }
  .whatsapp-icon-1 {
    position: fixed;
    bottom: 90px;
    right: 10px;
  }

  .iicon-icon-all-1 {
    position: relative;
    display: inline-block;
    overflow: hidden;
    line-height: 10px;
  }
  .iicon-icon-all-1 img {
    width: 25px;
    height: 25px;
    line-height: 0px;
  }
  .iicon-icon-all-1 .img-top {
    display: none;
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 99;
  }
  .reachbox:hover .reach-icon .img-top {
    display: inline;
    background-color: #ae0015;
    border-radius: 7%;
  }
  .mainbanner {
    width: 100%;
    max-height: 100vh !important;
    overflow: hidden;
    padding: 0;
    position: relative;
  }
  .bannerimg img {
    position: relative;
    width: 100% !important;
    height: 100vh !important;
    object-fit: cover;
  }

  .aboutrow {
    position: relative;
    overflow: hidden;
  }
  .about-home .aboutrow .row .col-md-4 {
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr;
  }

  .aboutcontent div {
    height: auto;
    font-size: 15px;
    line-height: 30px;
    margin-bottom: 3px;
  }
  .aboutrow .aboutimg {
    // padding-top: 300px;
    position: relative;
    margin-bottom: 20px;
  }
  .aboutbg .about-wrapper p {
    margin: 15px 0px;
    font-size: 13px;
    line-height: 36px;
  }
  .about-wrapper .whitebtn {
    font-size: 16px;
    width: auto;
    padding: 10px 25px;
    text-align: center;
  }
  .about-wrapper .white-btn {
    font-size: 20px;
    width: 250px;
    padding: 10px;
    text-align: center;
  }

  // .schedule-section .row .col-lg-4 {
  //   height: 100vh;
  //   display: grid;
  //   grid-template-columns: 1fr;
  // }

  .schedule-section .whitebtn {
    margin: 5px 0px;
  }

  .schedule-section .orangebtn {
    margin: 5px 0px;
  }
  .overlay-bhadarvi {
    // min-height: 100vh !important;
    display: grid;
    grid-template-columns: 1fr;
  }
  // .whole-bhadarvi .bannercontent .heading-bhadarvi .contentinner {
  //   // margin-top: 25px !important;
  // }
  .schedule-section h2 {
    font-size: 30px;
    font-weight: bold;
    margin: 50px 0 13px !important;
  }
  .eventpost .eventcontent p {
    color: inherit;
    margin: 0;
    font-size: 13px;
  }
  .eventpost {
    position: relative;
    display: flex;
    align-items: flex-end;
    margin-bottom: 0px;
  }
  // .schedule-section {
  //   margin: 25px 0px;
  // }
  .service-section {
    padding: 50px 0px;
  }
  .societybox {
    margin: 0px 0px 15px 0px;
  }
  .shop-section {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .prashad-section {
    // margin-bottom: 30px;
    // margin-top: 30px;
    padding: 35px 0px;
  }
  .center {
    align-items: center;
    justify-content: center;
    padding: 30px 10px;
    min-height: 100vh;
  }
  .headingdiv {
    top: 0%;
  }
  .iicon-icon-all-a {
    width: 25px;
    height: 20px;
    position: relative;
    top: -10px;
  }
  .societybox .societybox-img-shop {
    height: 300px !important;
    overflow: hidden;
  }
  .societybox .societybox-img img {
    width: 100%;
    height: 300px;
    position: relative;
    object-fit: cover;
    background-size: cover;
    background-position: center center;
  }
  .aboutrow {
    max-height: 100%;
    padding: 30px 0px;
  }
  .about-wrapper {
    max-height: 100%;
    padding: 30px 0px;
  }
  .max-heighest {
    max-height: 100%;
    padding: 30px 0px;
  }
  .society-wrapper {
    max-height: 100%;
    padding: 30px 0px;
  }
  .prashad-wrapper {
    max-height: 100%;
    padding: 30px 0px;
  }

  .prashadimg img {
    width: 100%;
    position: relative;
  }
  // .prashadimg-1 img{width: 100%;position: relative; display:inline-block !important;}

  .reachrow {
    padding: 15px 15px;
  }
  .reachbox {
    position: relative;
    padding: 0 0 22px 64px;
  }
  .footerbox {
    margin-bottom: 15px !important;
    min-height: 90%;
    height: auto;
  }
  .service-section h2 {
    font-size: 20px;
    margin: 25px 0 6px;
  }
  .aboutcontent p {
    height: auto;
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 575px) {
  .whatsapp-icon-1 {
    bottom: 90px;
    position: fixed;
    right: 10px;
  }
  .headingdiv {
    top: 75px;
    right: 10px;
  }
  .templeicon {
    top: 50px;
    right: -4px;
  }
  .headingdiv {
    position: absolute;
    width: 170px;
    height: 170px;
    font-size: 30px;
  }
  .headingdiv span {
    display: block;
    font-size: 16px;
    font-weight: 600;
    padding-top: 9px;
  }
  .aboutbg .about-wrapper p {
    margin: 12px 0px;
    font-size: 14px;
    line-height: 27px;
  }
  .about-wrapper .whitebtn {
    font-size: 15px;
    width: 210px;
    padding: 10px;
  }
  .about-wrapper .white-btn {
    font-size: 15px;
    width: 210px;
    padding: 10px;
  }
  .societybox-body {
    padding: 10px;
  }
  .aboutbg .about-wrapper h2 {
    font-size: 23px;
    margin-bottom: 15px;
    line-height: 39px;
  }
  .eventbox .event-timeicon {
    font-size: 42px;
    border-right: 1px solid #ffcc89;
    width: 85px;
  }
  .eventbox {
    padding: 20px 20px 20px 100px;
    min-height: 100px;
  }

  .aboutcontent div {
    height: auto;
    font-size: 13px;
    line-height: 24px;
    margin-bottom: 3px;
  }
  .aboutrow .aboutcontent h2 {
    margin-bottom: 10px;
    font-size: 19px;
    padding: 0px 0px;
  }
  .eventpost {
    padding-top: 100%;
  }
  .eventpost .eventcontent {
    position: absolute;
    padding: 0 7px 10px;
  }
  .contentinner {
    max-width: 580px;
    margin-left: 30px;
  }
  .bannercontent h1 {
    color: #ffffff;
    margin: 0 0 25px;
    font-size: 40px;
  }
  .read-more {
    margin-top: 0px;
  }
  .heading-textes {
    width: 100%;
  }
}

@media only screen and (min-width: 295px) and (max-width: 600px) {
  .servicebox {
    height: auto;
    padding: 15px;
    margin-bottom: 15px;
  }

  .whatsapp-icon-1 {
    bottom: 20px;
    position: fixed;
    right: 10px;
  }
  .servicebox .service-icon {
    width: 45px;
    height: 45px;
    margin-bottom: 7px;
  }
  .footerbox {
    height: auto;
    padding: 20px 20px 20px 100px;
    border-radius: 10px;
    position: relative;
    margin-bottom: 10px;
  }
  .footerbox .footericon {
    position: absolute;
    top: 45%;
    left: 20px;
    width: 65px;
    transform: translateY(-50%);
    font-size: 30px;
    line-height: 0;
    text-align: center;
  }
  .iicon-icon-all {
    width: 30px;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1024px) {
  .whatsapp-icon-1 {
    position: fixed;
    bottom: 20px;
    right: 10px;
  }
  .scroll-top-btn {
    position: fixed;
    bottom: 10%;
  }
}

// for custom navigation

.swiper-button-next {
  color: $primary !important;
}
.swiper-button-prev {
  color: $primary !important;
}

@media only screen and (max-width: 768px) {
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 25px;
  }
  .swiper-pagination-bullet {
    margin: 8px 5px !important;
  }
  .iicon-icon-all {
    width: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .reachbox {
    position: relative;
    padding: 0 0 10px 100px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .eventbox p {
    margin: 0;
    margin: 0px 0px;
    font-size: 14px;
  }
  .todaydate {
    margin-bottom: 7px;
    padding: 5px;
  }
  .eventbox {
    padding: 5px 10px 5px 117px;
    border-radius: 8px;
    margin-bottom: 7px;
    position: relative;
    font-size: 13px;
    line-height: 25px;
    min-height: 90px;
  }
  .eventbox h5 {
    padding: 0px 0;
    margin: 0;
  }
  .todaydate .date {
    font-size: 20px;
  }
  .todaydate .templeimg {
    width: 75px;
    height: 75px;
    padding: 5px;
  }
  .eventbox .event-timeicon img {
    filter: brightness(0);
    height: 35px;
    width: auto;
  }
  .eventbox .event-timeicon {
    width: 95px;
  }
}
