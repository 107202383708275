.checkout-info {
  background-color: #ae0015;
  display: flex;
  flex-wrap: wrap;
  flex-flow: column;
  /* justify-content: center; */
  padding: 40px 40px;
  width: 40%;
  color: #fff;
}

.checkout-info .product-thumbnail img {
  width: 90px;
  max-width: 90px;
}

.address-container {
  width: 100%;
}

.address-container .card {
  height: 99%;
  border: 2px solid rgba(0, 0, 0, 0.175);
}

.address-container .card.active {
  border: 2px solid #ae0015;
  color: #000;
}

.address-container p {
  margin-bottom: 2px;
  font-size: 14px;
}

.address-container input[type="radio"] {
  width: 20px;
  height: 20px;
}
.checkout-info input[type="checkbox"] {
  accent-color: #fff !important;
  width: 18px;
  height: 18px;
  margin-right: 7px;
  margin-top: 3px;
}

.checkout-info input[type="checkbox"]:checked {
  accent-color: #fff !important;
  width: 18px;
  height: 18px;
}
.cart-totals .react-datepicker__view-calendar-icon input {
  padding: 0px 10px 0px 30px;
  height: 30px;
  box-shadow: none;
  border: none;
}
.cart-totals .react-datepicker__view-calendar-icon input:focus {
  border: none;
}
.cart-totals .react-datepicker__view-calendar-icon input:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}
.cart-totals select {
  width: 100%;
  height: 40px;
  padding: 0px 10px;
}

.cart-totals select:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}
.cart-totals select option .order-total {
  display: flex;
  justify-content: space-between;
}
@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .address-container input[type="radio"]:checked {
    accent-color: #ae0015;
  }
}

@media screen and (max-width: 1061px) {
  .contact-form {
    width: 100%;
  }
  .checkout-info {
    width: 100%;
    padding: 35px 20px;
    margin: 10px 0px;
  }
}
