@media print {
  body {
    background-color: white !important; /* Set background color to white */
    margin: none;
  }
}

@page {
  size: A4;
  margin: 0;
}

.page-break {
  page-break-after: always;
}
.seva-camp {
  padding: 4% 0% 0% 0%;
}

.seva-camp-box {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 30px;
  background-color: #ffffff;
}
.seva-camp-box label {
  font-size: 15px;
  font-weight: 600;
  line-height: 28px;
  margin: 0 0 7px;
}
.seva-camp-box .checkbox-boxes-seva {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.search-sthanik .heding {
  background: #ae0015;
  display: inline-block;
  font-size: 17px;
  color: #fff;
  padding: 10px 30px;
  border-radius: 70px;
  margin: 5px 0px;
}
.search-sthanik .header h1 {
  font-size: 23px;
  margin: 0px 0px;
  line-height: 35px;
  color: #a30018;
  font-weight: bold;
}
.search-sthanik .table-responsive table {
  width: 500px;
  border-spacing: 0px;
}
.search-sthanik .table-responsive table tr td {
  padding: 7px 15px;
  font-size: 15px;
  line-height: 25px;
  font-weight: 700;
  color: #000;
  border: none;
  letter-spacing: 0.5px;
}
.search-sthanik .table-responsive table tr td span {
  font-size: 15px;
  line-height: 25px;
  font-weight: 400;
  padding: 0px 4px;
  color: #000000;
  letter-spacing: 0.5px;
}
@media only screen and (max-device-width: 768px) {
  .seva-camp h3 {
    font-size: 17px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .seva-camp-box {
    padding: 15px;
  }
  .seva-camp-box label {
    font-size: 14px;
    line-height: 26px;
    margin: 0 0 5px;
  }
  .seva-camp-box .checkbox-boxes-seva {
    width: 14x;
    height: 14px;
    margin-right: 5px;
    margin-top: 7px;
  }
}
