.privacy-policy {
  margin: 4% 0%;
}
.privacy-text {
  padding: 0px 0px 10px;
}
.privacy-text h2 {
  color: #ae0015;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin: 1px 0px;
}
.privacy-text p {
  color: #000;
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
  margin: 0px;
}
.privacy-text ul li a {
  color: #ae0015;
  font-weight: 600;
}
.privacy-text p a {
  color: #ae0015;
  font-weight: 600;
}
.privacy-text ul {
  padding-left: 20px;
  margin: 0px;
}
.privacy-text ul li {
  color: #000;
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
  margin: 0px 0px;
}
