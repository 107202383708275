.ThankYou-page-1 {
  padding: 5% 0%;
}
.bhadarvi-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin: 20px 0px 20px;
}
.bhadarvi-text p {
  margin: 0px;
}
.search-boxes {
  padding: 12px 25px;
  background: transparent;
  border: 1px solid #626262;
  border-radius: 5px;
}

.bhadarvi-search-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.bhadarvi-search-box :focus-visible {
  outline: none;
}

.boxer-bhadarvi {
  background: #ffffff8a;
  border: 1px solid #fff;
  border-radius: 20px;
  box-shadow: inset 0 0 0 4px #ffd801;
  margin: 20px auto 15px;
  padding: 40px;
}
.boxer-bhadarvi p {
  margin: 0px;
  padding: 10px 0px;
  line-height: 30px;
  letter-spacing: 0.2px;
}
@media only screen and (max-width: 767px) {
  .bhadarvi-box {
    display: block;
  }
  .bhadarvi-search-box {
    gap: 10px;
  }
  .bhadarvi-text p {
    margin: 0px 0px 15px;
    text-align: center;
  }
  .boxer-bhadarvi {
    border-radius: 10px;
    box-shadow: inset 0 0 0 4px #ffd801;
    margin: 5px auto 5px;
    padding: 20px;
  }
  .boxer-bhadarvi p {
    margin: 0px;
    padding: 0px;
    line-height: 30px;
    letter-spacing: 0.2px;
  }
  .bhadarvi-decoration {
    margin-bottom: 20px;
  }
  .bhadarvi-decoration:last-child {
    margin-bottom: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bhadarvi-search-box {
    gap: 10px;
  }
}
