@media print {
  body {
    background-color: white !important; /* Set background color to white */
  }
}
@page {
  size: A4;
  margin: 0;
}
table {
  margin: 0 auto !important;
}
img {
  -ms-interpolation-mode: bicubic;
}
a {
  text-decoration: none;
}
*[x-apple-data-detectors],
.unstyle-auto-detected-links *,
.aBn {
  border-bottom: 0 !important;
  cursor: default !important;
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.email-container {
  background: #fff;
  padding: 10px 15px;
}
.header .logo {
  width: 100px;
  height: 100px;
  margin-right: 8px;
}
.header .logo img {
  width: 100%;
  height: auto;
}
.header h1 {
  font-size: 17px;
  margin: 0px 0px;
  line-height: 25px;
  color: #a30018;
}
.header p {
  font-size: 14px;
  margin: 0px;
  line-height: 24px;
  color: #333;
}

.headig-text h2 {
  font-size: 20px;
  padding: 15px 0px;
  margin: 15px 0px 15px;
  color: #a30018;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}
.teg-line p {
  font-size: 14px;
  margin: 3px 0px;
  line-height: 24px;
  color: #333;
}

.date-section {
  font-size: 14px;
  line-height: 25px;
  color: #333;
  background-image: url(https://storage.googleapis.com/ambaji-temple.appspot.com/1715936839975_Ambajimandirbackgroundimgforreceipt.png);
  background-size: 35% auto;
  background-position: bottom right;
  background-repeat: no-repeat;
  margin: 10px 0px !important;
}
.date-section tr td {
  border: 1px solid #e3e3e3;
  padding: 7px 10px;
  font-size: 14px;
  background-color: #f2f2f275;
}

.rs-box {
  height: 40px;
  border: 2px solid #a30018;
  overflow: hidden;
}

.rs-box p {
  font-size: 16px;
  margin: 0px;
  line-height: 25px;
  color: #333;
  font-weight: 700;
}

.rs-box p span {
  background-color: #a30018;
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  margin: -1px;
  padding: 18px;
  margin-right: 6px;
}
.rs-box-text p {
  font-size: 14px;
  margin: 3px 6px;
  line-height: 24px;
  color: #333;
}
.rs-note p {
  font-size: 14px;
  margin: 0px 0px 0px;
  line-height: 24px;
  color: #333;
}
.rs-note p span {
  font-weight: 700;
  font-size: 14px;
  margin: 32px 7px;
}
.sign-trust p {
  padding: 10px 15px;
  margin: 8px 0px;
  font-size: 14px;
  line-height: 24px;
  border: 2px solid #f4f4f4;
  color: #333;
}
.sign-vahivat p {
  margin: 11px 0px;
  font-size: 14px;
  line-height: 24px;
  color: #333;
  font-weight: 600;
}
.download-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}
.pdf-btn {
  position: relative;
}
.download-btn svg {
  width: 30px;
  height: 30px;
  color: #fff;
  background-color: #b043507c;
  padding: 2px;
  border-radius: 5px;
}
.download-btn svg:hover {
  background-color: #a30018;
  cursor: pointer;
  transition: 0.5s;
}
@media only screen and (max-device-width: 768px) {
  .email-container {
    width: 100% !important;
    padding: 10px 10px;
  }
  .header p {
    font-size: 10px;
    margin: 0px;
    line-height: 20px;
  }
  .rs-note p {
    font-size: 10px;
    margin: 0px 0px 10px;
    line-height: 18px;
  }
  .header h1 {
    font-size: 13px;
    margin: 0px 0px 5px;
    line-height: 20px;
    color: #a30018;
  }
  .date-section tr td {
    padding: 4px 4px;
    font-size: 10px;
  }
  .headig-text h2 {
    font-size: 15px;
    padding: 5px 0px;
    margin: 5px 0px 5px;
  }
  .teg-line p {
    font-size: 10px;
    margin: 0px 0px;
    line-height: 17px;
  }
}
