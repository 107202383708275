.bhadarvi-bg {
  background-image: url('../../Assets/Images/Bhadarvi_poonam/ambaji-night-badarvi.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
@media only screen and (max-width: 767px) {
  .bhadarvi-bg {
    background-image: url('../../Assets/Images/Bhadarvi_poonam/ambaji-night-badarvi-mobile.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.main-heading-bhadarvi {
  display: flex;
  height: 100vh;
  position: relative;
  flex-direction: column;
}
// .main-heading-bhadarvi .bhadarvi-text img {
//   top: 20%;
//   position: absolute;
//   font-size: 80px;
//   z-index: 1;
//   margin: 0;
//   color: #ffd801;
// }
// .main-heading-bhadarvi .bhadarvi-text {
//   font-size: 100px;
//   z-index: 1;
//   margin: 0;
//   display: block;
//   font-weight: 700 !important;
//   color: #ffd801;
// }
.main-heading-bhadarvi .bhadarvi-text {
  margin-top: 150px;
  position: relative;
}

.main-heading-bhadarvi .bhadarvi-text img {
  width: 100%;
  height: auto;
  position: relative;
}
.arrow_scroll_down {
  position: absolute;
  display: inline-block;
  left: 50%;
  bottom: 50px;
  text-align: center;
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 1;
}
.arrow_scroll_down img {
  display: inline-block;
  width: 30px !important;
  height: auto !important;
  position: relative;
  color: #ffd801;
  animation: bounce 3s infinite;
}

.arrow_scroll_down p {
  margin: 0;
  font-size: 17px;
  color: #ffd801;
  margin-top: 10px;
  text-transform: capitalize;
}
@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0px);
  }
}
.bhadarvi-bg:before {
  content: '';
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.box-modals {
  margin: 0px 40px 0px 0px;
  padding: 65px 0px 65px;
  background-clip: padding-box;
}

.overlay-box {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.85);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
}
.overlay-box:target {
  visibility: visible;
  opacity: 1;
}

.popup-box {
  margin: 7% auto;
  padding: 20px;
  background: #ffd801;
  border-radius: 5px;
  width: 50%;
  position: relative;
}
.popup-box h2 {
  margin: 0px;
  font-size: 25px;
  color: #ae0015;
}
.popup-box .close {
  position: absolute;
  top: 28px;
  right: 14px;
  transition: all 200ms;
  font-size: 51px;
  font-weight: normal;
  line-height: 0;
  text-decoration: none;
  color: #000;
  z-index: 5;
}
.popup-box .close:hover {
  color: #ae0015;
}
.popup-box .content-show {
  max-height: 30%;
  overflow: auto;
}

.table-responsive-1 {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.table-responsive-1 table {
  width: 100%;
  border: 1px solid #ae0015;
  background-color: #fff;
}

.table-responsive-1 th {
  text-align: center;
  padding: 5px;
  color: #fff;
  background-color: #ae0015;
  font-size: 15px;
}
.table-responsive-1 td {
  text-align: center;
  padding: 5px;
  border: 1px solid #ae0015;
  color: #000;
  font-weight: 500;
  font-size: 15px;
}

.popup-box hr {
  margin: 0.5rem 0;
}

.popup-box h2 {
  margin: 0px;
  font-size: 20px;
  color: #ae0015;
  display: inline-block;
}

#popContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto auto;
  text-align: center;
  transform: translate(-0%, -0%);
  background-color: #000000ba;
}

#popContainer img {
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  height: auto;
}

#popContainer #myModal {
  display: flex;
}

div#popUpForm {
  width: 100%;
  display: block;
  z-index: 3;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}

#close {
  padding: 0px;
  float: right;
  margin: 5px 5px 0px 0px;
  cursor: pointer;
  border-radius: 2px;
  font-weight: bold;
  font-size: 19px;
  line-height: 25px;
  color: #aa0115;
  right: 0;
  position: absolute;
  width: 35px;
  height: 35px;
  border: none;
}
#close:hover {
  color: #000;
  transition: 1s;
}
#popContainer .modal-content {
  width: 25%;
}
.registration-button {
  font-size: 19px;
}
.bhadarvi-box-color {
  display: flex;
  align-items: baseline;
  gap: 13px;
  flex-direction: column;
  background-color: #ffffff;
  margin-bottom: 21px;
  border-radius: 10px;
  padding: 25px;
  min-height: 80%;
  text-align: left;
}
.bhadarvi-box-color:hover {
  color: #ae0015;
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.4);
}
.bhadarvi-box-color .service-icon img {
  filter: brightness(0);
  height: 65px;
  width: 65px;
}
.bhadarvi-box-color:hover img {
  filter: brightness(1);
}
.bhadarvi-box-color h5 {
  margin: 0px;
  line-height: 25px;
}

.progressbar-devotees {
  margin: 15px 0px;
}

.progressbarbox {
  width: 100%;
}
.progressbarbox div {
  height: 12px !important;
}

.donatin-value {
  display: flex;
  justify-content: space-between;
  margin: 15px 0px;
}
.donatin-rupees-hed-text h4 {
  margin: 15px 0px;
  color: #ae0015;
  line-height: 30px;
}
.donatin-rupees-hed-text h4 span {
  color: #000;
  font-size: 17px;
  display: block;
}
.donatin-rupees p {
  color: #000;
  font-weight: 600;
  display: block;
  line-height: 28px;
  font-size: 17px;
}
.donatin-rupees p span {
  color: #2c2c2c;
  font-weight: 400;
  display: block;
  font-size: 15px;
}

.donation-btn {
  margin: 15px 0px 5px;
}
.icon-text-box {
  display: flex;
  align-items: center;
  gap: 10px;
}
.icon-text-box p {
  font-size: 16px;
  margin: 0px;
  color: #000;
  font-weight: 600;
  line-height: 26px;
}
@media only screen and (max-width: 1024px) {
  #close {
    margin: 0px;
    border-radius: 3px;
    font-size: 14px;
    line-height: 5px;
    width: 27px;
    height: 30px;
  }
}
@media only screen and (max-width: 768px) {
  .main-heading-bhadarvi .bhadarvi-text {
    margin-top: 100px;
    position: relative;
  }
  .main-heading-bhadarvi .bhadarvi-text img {
    width: 75% !important;
    height: auto !important;
    position: relative;
  }

  .box-modals {
    width: 90%;
    margin: 0px 0px 0px 0px;
    padding: 15px 0px 30px;
  }
  .popup-box {
    width: 100%;
    margin: 30% auto;
    padding: 15px;
  }
  .popup-box h2 {
    margin: 0px;
    font-size: 20px;
    color: #ae0015;
  }
  .table-responsive-1 td {
    text-align: center;
    padding: 5px;
    font-size: 13px;
    font-weight: 500;
  }
  .table-responsive-1 th {
    text-align: center;
    padding: 5px;
    font-size: 13px;
  }
  .popup-box .close {
    position: absolute;
    top: 25px;
    right: 13px;
    transition: all 200ms;
    font-size: 36px;
  }
  .heading-bhadarvi {
    width: 100%;
    margin: auto 5%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .box-modals {
    margin: 0px 0px 0px 0px;
    padding: 50px 0px;
    background-clip: padding-box;
  }
  .main-heading-bhadarvi .bhadarvi-text h1 {
    top: 25%;
    position: absolute;
    font-size: 60px;
  }
  .main-heading-bhadarvi .bhadarvi-text h1 span {
    font-size: 70px;
  }
}
@media only screen and (max-width: 767px) {
  .Advertise-business h3 {
    line-height: 35px;
  }
  .bhadarvi-box-color .service-icon img {
    filter: brightness(0);
    height: 50px;
    width: 50px;
  }
  .heading-bhadarvi {
    width: 100%;
    margin: auto 0%;
  }
  .registration-button {
    font-size: 16px !important;
    font-weight: 600 !important;
    padding: 8px 15px !important;
    margin: 0px 2px !important;
  }
  .sevamain {
    padding: 7px;
  }
  #popContainer .modal-content {
    width: 90%;
  }
  .main-heading-bhadarvi .bhadarvi-text h1 {
    top: 25%;
    position: absolute;
    font-size: 40px;
  }
  .main-heading-bhadarvi .bhadarvi-text h1 span {
    font-size: 45px;
  }
  .bhadarvi-box-color {
    gap: 10px;
    flex-direction: column;
    margin-bottom: 18px;
    border-radius: 10px;
    padding: 20px;
    min-height: 80%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #popContainer .modal-content {
    width: 30%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1440px) {
  #popContainer .modal-content {
    width: 25%;
  }
  .main-heading-bhadarvi .bhadarvi-text h1 {
    font-size: 60px;
  }
  .main-heading-bhadarvi .bhadarvi-text h1 span {
    font-size: 70px;
  }
}
