.guidelines {
  margin: 50px 0px;
}

.tabs {
  display: flex;
  justify-content: space-evenly; /* Distribute tabs evenly */
  margin: 20px 0;

  .tab {
    display: flex; /* Use flexbox to align text */
    justify-content: center; /* Horizontally center the text */
    align-items: center; /* Vertically center the text */
    text-align: center; /* Ensure multi-line text is centered */
    padding: 10px 20px;
    cursor: pointer;
    color: #333;
    border-bottom: 1px solid #000; /* Individual underline for each tab */
    font-weight: bold; /* Make the text bold */
    transition: color 0.3s, border-color 0.3s;

    &:hover {
      color: #ae0015; /* Change text color on hover */
    }

    &.active {
      background-color: #ae0015; /* Red background for the active tab */
      color: #fff; /* White text for contrast */
      font-weight: bold; /* Ensure active tab text is bold */
      border: 2px solid #ae0015; /* Match the background color */
      border-bottom: none; /* Remove underline for the active tab */
      border-radius: 30px; /* Rounded corners for active tab */
    }
  }
}

.tab-content {
  margin-top: 20px;
  text-align: center;

  .red-box {
    padding: 20px;
    border: 2px solid #ae0015;
    background-color: #ffecec;
    color: #ae0015;
    border-radius: 30px;
  }
}
/* Style for the red circular loader */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7); /* Slight overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's on top of other content */
}

.spinner {
  border: 4px solid #f3f3f3; /* Light gray background */
  border-top: 4px solid #ae0015; /* Red color for the top */
  border-radius: 50%; /* Make it circular */
  width: 50px; /* Set the size of the loader */
  height: 50px;
  animation: spin 1s linear infinite; /* Infinite rotation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust based on your tab content's height */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #ae0015;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.tab-loader {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 300px; /* Adjust based on the height of your tab content */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #ae0015;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
