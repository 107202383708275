@media print {
  body {
    background-color: white !important; /* Set background color to white */
    margin: none;
  }
}
@page {
  size: A4;
  margin: 0;
}
.page-break {
  page-break-after: always;
}
table {
  margin: 0 auto !important;
}
img {
  -ms-interpolation-mode: bicubic;
}
a {
  text-decoration: none;
}
*[x-apple-data-detectors],
.unstyle-auto-detected-links *,
.aBn {
  border-bottom: 0 !important;
  cursor: default !important;
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.detail-container {
  background: #fff;
  padding: 15px 15px;
  background-image: url(https://storage.googleapis.com/ambaji-temple.appspot.com/1715936839975_Ambajimandirbackgroundimgforreceipt.png);
  background-size: 60% auto;
  background-position: bottom right 15px;
  background-repeat: no-repeat;
  margin: 0px auto !important;
  width: 820px;
}
.header-main .main-logo {
  width: 100px;
  height: 100px;
  margin-right: 8px;
}
.header-main .main-logo img {
  width: 100%;
  height: auto;
}
.header-main h1 {
  font-size: 17px;
  margin: 0px 0px;
  line-height: 25px;
  color: #a30018;
}
.header-main p {
  font-size: 14px;
  margin: 0px;
  line-height: 24px;
  color: #333;
}
.headig-text-1 h2 {
  font-size: 20px;
  padding: 10px 0px;
  margin: 5px 0px 5px;
  color: #a30018;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  text-transform: uppercase;
}
.teg-line p {
  font-size: 14px;
  margin: 3px 0px;
  line-height: 24px;
  color: #333;
}
.images-section-1 {
  font-size: 14px;
  line-height: 25px;
  color: #333;
  padding: 0px 0px;
  margin: 10px 0px !important;
}
.images-section-1 tr td {
  border: 1px solid #e3e3e3;
  padding: 7px 10px;
  background-color: rgba(242, 242, 242, 0.4588235294);
}

.rs-box {
  height: 40px;
  border: 2px solid #a30018;
  overflow: hidden;
}

.rs-box p {
  font-size: 16px;
  margin: 0px;
  line-height: 25px;
  color: #333;
  font-weight: 700;
}

.rs-box p span {
  background-color: #a30018;
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  margin: 0px;
  padding: 18px;
  margin-right: 6px;
}
.rs-box-text p {
  font-size: 14px;
  margin: 3px 6px;
  line-height: 24px;
  color: #333;
}
.rs-note-service p {
  color: #a30018;
  font-weight: 700;
  font-size: 17px;
  margin: 0px 0px 5px;
  line-height: 30px;
}
.rs-note-service p span {
  font-weight: 500;
  font-size: 15px;
  display: block;
  color: #333;
}
.barcode {
  padding: 5px 0px;
}
.sign {
  padding: 5px 0px;
}
.sign-trust p {
  padding: 10px 15px;
  margin: 8px 0px;
  font-size: 14px;
  line-height: 24px;
  border: 2px solid #f4f4f4;
  color: #333;
}
.sign-vahivat p {
  margin: 11px 0px;
  font-size: 14px;
  line-height: 24px;
  color: #333;
  font-weight: 600;
}

.note-introduction {
  margin: 25px 0px;
  border-top: 1px solid #f2f2f2;
}

.notice h2 {
  font-size: 18px;
  margin: 0px 0px 0px;
  line-height: 30px;
  letter-spacing: 0.1px;
  color: #ae0015;
}
.notice ol {
  margin: 0px;
  padding: 10px 0px 0px 20px;
}
.notice li {
  font-size: 14px;
  margin: 7px 0px;
  line-height: 23px;
  color: #333;
}
@media only screen and (max-device-width: 768px) {
  .header-main p {
    font-size: 10px !important;
    line-height: 18px !important;
  }

  .header-main h1 {
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .headig-text-1 h2 {
    font-size: 15px;
    padding: 5px 0px;
    margin: 7px 0px 7px;
  }
  .images-section-1 tr td {
    font-size: 10px;
    line-height: 18px;
    padding: 5px 5px;
  }
  .notice h2 {
    font-size: 15px !important;
  }
  .notice li {
    font-size: 10px !important;
    margin: 5px 0px;
    line-height: 21px;
  }
  .barcode img {
    width: 70px;
    height: auto;
  }
  .sign img {
    width: 70px;
    height: auto;
  }
}
