.shakti-banner {
  padding: 3% 0%;
}

.shakti-box {
  border-radius: 10px;
  background-color: #ffffff;
  overflow: hidden;
  margin: 15px 0px;
}
.shakti-box .shakti-img {
  height: 300px;
  overflow: hidden;
}
.shakti-box .shakti-img img {
  width: 100%;
  object-fit: cover;
}

.shakti-body {
  padding: 15px;
}
.shakti-body h5 {
  font-size: 16px;
  font-weight: bold;
  color: #ae0015;
  text-transform: capitalize;
  margin-bottom: 10px;
  margin-top: 10px;
}

.shakti-banner .date-section p {
  font-size: 20px;
  font-weight: 600;
  color: #ae0015;
  padding-right: 30px;
}

.shakti-banner .date-section .react-datepicker-wrapper {
  margin-right: 10px;
}
.shakti-banner .date-section {
  margin-bottom: 15px;
  background-image: none;
}
.shakti-banner .date-section input:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
  border: 0px;
}
.shakti-banner .date-section input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  padding: 10px 15px;
  border: 0px;
  font-size: 16px;
  letter-spacing: -0.1px;
}

.Subscribe-section {
  margin: 4% 0%;
  align-items: center;
  display: flex;
}
.Subscribe-section .Subscribe h3 {
  font-size: 30px;
  padding-bottom: 0px;
  margin-bottom: 20px;
  color: #000000;
}
.Subscribe-section .Subscribe h3 span {
  color: #ae0015;
}
.Subscribe-section .Subscribe p {
  width: 90%;
  font-size: 16px;
  line-height: 30px;
  margin: 20px 0px;
}
.Subscribe-section .Subscribe-img {
  border-radius: 10px;
  overflow: hidden;
  height: 450px;
}

.Subscribe-section .Subscribe-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Advertise-business {
  margin: 4% 0%;
  align-items: center;
  display: flex;
}

.Business-text h3 {
  font-size: 30px;
  padding-bottom: 0px;
  margin-bottom: 20px;
  color: #000000;
}
.Business-text h3 span {
  color: #ae0015;
}
.magzine-img {
  width: 260px;
  height: auto;
  margin: 0 auto;
  margin-bottom: 0px;
  position: relative;
  margin-top: -60px;
}
.pack-price-cart {
  border-radius: 10px;
  background: #ae0015;
  box-shadow: 0px 4px 15px 6px rgba(0, 0, 0, 0.1);
  padding: 5px 5px 5px;
  display: flex;
  flex-direction: column;
  margin: 15px 0px;
}
.pack-price-cart-whitebg {
  border-radius: 10px;
  background: #fff;
  padding: 12px 0px 12px;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}
.pack-price-cart-gryBg {
  background: #f2f2f2;
  width: 100%;
  height: auto;
  padding: 20px 0px 40px;
  position: relative;
}
.pricebd .sp-price {
  color: #000;
  font-size: 53px;
  line-height: 65px;
  letter-spacing: -3.36px;
}
.pack-price-cart h4 {
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.27px;
  text-transform: uppercase;
  margin-bottom: 5px;
  margin-top: 3px;
}
.pack-price-cart-whitebg h5 {
  color: #ae0015;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  margin: 5px 20px 10px;
}
.pack-price-cart-whitebg h2 {
  color: #000;
  text-align: center;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  margin-bottom: 20px;
  letter-spacing: -0.42px;
  width: 100%;
}
.pricebd {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.rssing {
  font-size: 22px;
  margin-right: 3px;
  line-height: 40px;
}
.pack-price-cart-gryBg h5 {
  color: #6a6a6a;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  text-decoration-line: line-through;
  margin-bottom: 11px;
  letter-spacing: -0.26px;
  margin: 0px 20px 25px 20px;
}
.percengetoff {
  width: 100%;
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.15px;
}
.pricebd .inyear {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 62px;
  letter-spacing: -0.22px;
  margin-left: 5px;
}
.Cornerside h6 {
  color: #fff;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 74px;
  position: absolute;
  top: -44px;
  right: 8px;
  letter-spacing: -0.19px;
}
.Cornerside h5 {
  color: #fff;
  text-align: right;
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: 74px;
  transform: rotate(34.17deg);
  position: absolute;
  top: -17px;
  right: -10px;
  letter-spacing: -0.35px;
  margin: 0px 0px 0px 0px;
}

.linkbtn {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}
