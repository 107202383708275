.auth {
  margin: 4% auto;
}
.auth .card {
  padding: 30px;
}
/* .auth .card .orangebtn {
  width: 100%;
} */

.auth .card .orangebtn:hover {
  border: 2px solid #ae0015;
}

.auth .card .form-footer p span {
  color: #ae0015;
  text-decoration: underline;
  cursor: pointer;
}

.auth .card .form-footer p span:hover {
  text-decoration: none;
}

.auth .forgot-link {
  cursor: pointer;
  text-decoration: underline;
  color: blue;
  display: inline-block;
  float: right;
}

.auth .input-group-text {
  padding: 11px;
  border-radius: 0px 5px 5px 0px;
}

@media only screen and (max-width: 991px) {
  .auth .card {
    max-width: 100%;
    margin: 10% auto !important;
    padding: 25px 10px;
  }
}
.span-link {
  color: #ae0015;
  text-decoration: underline;
  cursor: pointer;
}
