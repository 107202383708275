@import '../../Css/variable.scss';

.about-page {
  margin: 4% 0%;
}

.header-banner {
  padding: 150px 0px 70px;
  position: relative;
  // margin-top: 88px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  &:before {
    content: '';
    background-color: $black;
    opacity: 0.35;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
.header-banner h1 {
  margin: 0;
  position: relative;
  z-index: 1;
  color: #ffffff;
  text-transform: capitalize;
  text-align: center;
  font-size: 32px;
  line-height: 55px;
  font-weight: 700;
}
.header-banner p {
  margin: 0;
  position: relative;
  z-index: 1;
  color: #ffffff;
  text-transform: capitalize;
  text-align: center;
  font-size: 32px;
  line-height: 55px;
  font-weight: 700;
}
.about-gallery {
  margin: 20px 0px 0;
  .about-img {
    overflow: hidden;
    position: relative;
    margin: 10px 0px;
    &:hover .zoomicon {
      opacity: 1;
      visibility: visible;
    }
    img {
      height: auto;
      width: 100%;
      object-fit: cover;
    }
    .zoomicon {
      background-color: $black;
      height: 36px;
      width: 36px;
      display: inline-block;
      border-radius: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 7px 0 0;
      text-align: center;
      transition: 0.5s all;
      cursor: pointer;
      color: $white;
      font-size: 18px;
      opacity: 0;
      visibility: visible;
    }
  }
}

.about-gallery .about-img .zoomicon img {
  background-color: #000000;
  height: 20px;
  width: 20px;
  display: inline-block;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0px 0 0;
  text-align: center;
  transition: 0.5s all;
  cursor: pointer;
  color: #ffffff;
  opacity: 0;
  visibility: visible;
}
.about-gallery .about-img:hover .zoomicon img {
  opacity: 1;
  visibility: visible;
}

.about-main {
  font-size: 14px;
  .about-nav {
    padding-bottom: 30px;
  }
  .about-tabs {
    h2 {
      font-size: 24px;
      margin-bottom: 18px;
    }
    h3 {
      font-weight: 600;
      font-size: 16px;
      margin: 12px 0 15px;
      display: inline-block;
      width: 100%;
    }
  }
}

.about-nav ul li a img {
  font-size: 20px;
  margin-right: 8px;
  filter: brightness(0);
  height: 25px;
  width: 25px;
}

.about-nav ul li:hover img {
  filter: brightness(1);
}

.about-nav {
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      display: inline-block;
      margin-bottom: 10px;
      color: black;
      background: white;
      border-radius: 8px;
      &:not(:last-child) {
        margin-right: 16px;
      }
      a {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        padding: 10px 20px;
        color: black;
        @media only screen and (max-width: 767px) {
          padding: 8px 15px;
        }
      }
      &.active a,
      &:hover a {
        background-color: $primary;
        color: white;
        border-color: $primary;
        border-radius: 8px;
      }
      &.active a img {
        filter: brightness(1); /* Ensure image is bright in active state */
      }
    }
  }
}

.donation-title h2 {
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 25px;
}
.templetop {
  padding-bottom: 35px;
  h2 {
    font-size: 24px;
    padding-bottom: 20px;
  }
}
.templeimg {
  position: relative;
  @media only screen and (max-width: 991px) {
    max-width: 500px;
    margin: 0 auto 50px;
  }
  // img {
  //   width: 100%;
  //   border-radius: 15px;
  // }
  img {
    height: 450px;
    width: 100%;
    object-fit: cover;
    border-radius: 15px;
  }
  .subimage {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    max-width: 150px;
    border: 4px solid $white;
  }
}
.aboutUs-banner {
  background-image: url('../../Assets/Images/Temple/contact.jpg');
}

// new-responsive code
.about-main .about-tabs p {
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 5px;
}
.templetop p {
  font-size: 14px;
  line-height: 26px;
  white-space: pre-wrap;
  margin-bottom: 5px;
}

@media only screen and (max-width: 767px) {
  .templeimg .subimage {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    max-width: 115px;
    border: 4px solid #ffd801;
  }
  .templeimg {
    max-width: 500px;
    margin: 0 auto 40px;
  }
  .templetop h2 {
    font-size: 24px;
    padding-bottom: 0px;
    margin: 0px 0px 14px;
  }
  .templetop {
    padding-bottom: 12px;
  }
  .temple-section {
    margin-top: 0%;
  }
  // section {
  //   padding: 20px 0;
  // }
  .about-main .about-tabs p {
    font-size: 16px;
    line-height: 26px;
  }
  .templetop p {
    font-size: 16px;
    line-height: 26px;
  }
  .about-main .about-nav {
    padding-bottom: 15px;
  }
  .about-main .about-tabs h2 {
    font-size: 18px;
    line-height: 33px;
  }
  .about-nav ul li:not(:last-child) {
    margin-right: 10px;
  }

  .header-banner {
    padding: 110px 0 25px;
  }
  .header-banner h1 {
    font-size: 25px;
    line-height: 40px;
  }
  .header-banner p {
    font-size: 25px;
    line-height: 34px;
    margin: 7px 0px 0px;
  }
  .leader-heading h2 {
    margin: 0px 0px 20px 0px;
  }
  .templeimg img {
    height: 300px;
    width: 100%;
    object-fit: cover;
    border-radius: 15px;
  }
}

@media only screen and (max-width: 1365px) {
  .templetop p {
    font-size: 13px;
    line-height: 22px;
  }
  .about-main .about-tabs p {
    font-size: 13px;
    line-height: 22px;
  }
  .templeimg img {
    height: 350px;
    width: 100%;
    object-fit: cover;
    border-radius: 15px;
  }
}
