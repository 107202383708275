.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 30px;
}

.spinner-btn {
  border-top: 3px solid #ae0015; /* Red top border */
  border-radius: 50%;
  animation: spin-round 1s linear infinite;
  width: 30px;
  height: 30px;
}

@keyframes spin-round {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
