.online-banner {
  background-image: url("../../../Assets/Images/E_store/AmbajiTemple_ECommerceHeader.png");
}

.online-se-banner {
  padding: 85px 0;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.online-se-banner:before {
  content: "";
  background-color: #000000;
  opacity: 0.35;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.online-se-banner h1 {
  margin: 0;
  position: relative;
  z-index: 1;
  color: #ffffff;
  text-transform: capitalize;
  text-align: center;
  font-size: 32px;
  padding-top: 40px;
}

.online-page {
  margin: 4% 0%;
}

.onlinebox {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 30px;
  height: calc(100% - 25px);
}

.onlinebox p {
  margin-top: 10px;
  font-size: 14px;
  margin-bottom: 10px;
  color: #333333;
  line-height: 28px;
}
.onlinebox:hover {
  color: #ae0015;
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.4);
}

.onlinebox .online-icon {
  font-size: 60px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.onlinebox .online-icon img {
  filter: brightness(0);
  height: 65px;
  width: 65px;
}

.onlinebox:hover img {
  filter: brightness(1);
}

@media only screen and (max-width: 767px) {
  .online-banner {
    padding: 85px 0 40px;
  }

  .online-se-banner h1 {
    font-size: 25px;
  }

  .onlinebox {
    height: auto;
    padding: 15px;
    margin-bottom: 15px;
  }
  .onlinebox p {
    font-size: 13px;
    color: #333333;
    line-height: 22px;
  }
}
