.dhaja-page {
  margin: 4% 0%;
}

.dhaja-booking {
  padding: 85px 0;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-image: url(../../Assets/Images/Temple/ambaji-mandir-dhaja.jpg);
}

.dhaja-booking h1 {
  margin: 0;
  position: relative;
  z-index: 1;
  color: #ffffff;
  text-transform: capitalize;
  text-align: center;
  font-size: 32px;
  padding-top: 40px;
}
.dhaja-booking:before {
  content: "";
  background-color: rgb(0 0 0 / 90%);
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.dhaja-booking-form {
  padding: 30px;
}

.dhaja-booking-form .react-datepicker__view-calendar-icon input {
  padding: 3px 10px 3px 40px;
  height: 44px;
  width: 100%;
  box-shadow: none;
}
.dhaja-booking-form .react-datepicker__view-calendar-icon input:focus {
  box-shadow: none;
}
.dhaja-booking-form .react-datepicker__view-calendar-icon input:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}
.dhaja-booking-form
  .react-datepicker__input-container
  .react-datepicker__calendar-icon {
  position: absolute;
  padding: 0.9rem;
  box-sizing: content-box;
}
.dhaja-booking-form .react-datepicker-popper {
  z-index: 2;
  line-height: 0;
}
.dhaja-booking-form .selected-time-slot {
  text-align: center;
}
.dhaja-booking-form .selected-time-slot select {
  width: 100%;
  height: 44px;
  padding: 10px;
}
.dhaja-booking-form .selected-time-slot select option {
  background-color: #fff;
  color: #000;
  height: 44px;
  width: 100%;
  padding: 0.9rem;
}
.dhaja-booking-form .selected-time-slot select:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

@media only screen and (max-width: 767px) {
  .dhaja-booking-form {
    padding: 15px;
  }
}
