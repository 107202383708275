/** Site colors */
$primary: #ae0015 !default;
$primary-light: #ff5c01 !default;
$secondary: #ffd801 !default;
$secondary-light: #ffcc89 !default;
$white: #ffffff !default;
$gray: #d9d9d9 !default;
$gray-dark: #333333 !default;
// $gray-dark: #fff !default;
$black: #000000 !default;
$red: #b01432 !default;
$yellow: #ffa900 !default;
