.shopDetailed-banner {
  background-image: url('../../../Assets/Images/E_store/AmbajiTemple_ECommerceHeader.png');
}

// .product-detail {
//   position: relative;
// }
// .product-gallery {
//   width: var(--theme-var-single-product-gallery-width);
//   float: left;
//   position: relative;
//   padding-left: 120px;
// }

.main {
  margin: 3rem auto 5rem;
  display: flex;
  flex-direction: column;
  max-width: 124rem;

  @media screen and (min-width: 900px) {
    margin: 3rem auto 5rem;
    flex-direction: row;
    align-items: center;
  }

  &__container-a {
    width: 100%;

    @media screen and (min-width: 900px) {
      width: 50%;
    }
  }

  &__container-b {
    width: 100%;
    max-width: 37.5rem;
    margin: 0 auto;
    padding: 0rem 4rem 0rem 0rem;

    // font-size: 1.5rem;
    @media screen and (min-width: 900px) {
      width: 50%;
      max-width: 50rem;
    }

    @media screen and (min-width: 1100px) {
      // font-size: 1.7rem;
    }
  }

  &__p {
    margin-bottom: 1.3rem;
    font-size: 14px;
    line-height: 26px;

    &--orange {
      margin-bottom: 1rem;

      @media screen and (min-width: 1100px) {
        font-size: 1.5rem;
      }
    }

    &--discount {
      margin-bottom: 0;
      color: #ae0015;
      font-weight: bold;
      background-color: #ffede0;
      padding: 0.3rem 1.2rem;
      border-radius: 30px;
      border: 2px solid #ffd801;
      cursor: pointer;
    }

    &--discount-active {
      border: 2px solid #ae0015;
    }

    &--number {
      font-weight: bold;
      margin-bottom: 0;
      color: black;
    }
  }

  &__h1 {
    color: #ae0015;
    text-transform: capitalize;
    font-size: 2rem;
    margin-bottom: 1rem;
    // margin-top: 1rem;
  }

  &__h2 {
    // font-size: 2.5rem;
    @media screen and (min-width: 1100px) {
      // font-size: 3rem;
    }
  }

  &__price-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    @media screen and (min-width: 900px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }

  &__current-price {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  &__previous-price {
    font-weight: bold;
    text-decoration: line-through;
  }

  &__buttons {
    display: flex;
    gap: 15px;
    // flex-direction: column;

    @media screen and (min-width: 900px) {
      flex-direction: row;
      gap: 2rem;
    }
  }

  &__number-box {
    width: 100%;
    background-color: #f7f8fd;
    border-radius: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (min-width: 900px) {
      width: 40%;
    }
  }

  &__number-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      opacity: 0.5;
    }
  }

  &__buy-button {
    width: 100%;
    margin-top: 0rem;
    margin: 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s;

    // box-shadow: 0 2rem 4rem -1.5rem #ae0015;
    &:hover .main__cart {
      fill: #ae0015;
    }

    @media screen and (min-width: 900px) {
      width: 40%;
      margin-top: 0;
    }
  }

  &__cart {
    margin-right: 1rem;
    fill: white;
    transition: all 0.3s;
  }
}

.main_text p span {
  font-size: 15px;
  line-height: 26px;
  padding: 14px 0px;
}

@media only screen and (max-width: 1365px) {
  .main__p {
    font-size: 13px;
    line-height: 22px;
    margin-bottom: 10px;
    padding-bottom: 15px;
    text-align: justify;
  }

  .main__container-b h1 {
    color: #ae0015;
    text-transform: capitalize;
    font-size: 23px;
    margin-bottom: 10px;
  }

  .main {
    margin: 0rem auto 0rem;
    display: flex;
    flex-direction: column;
    max-width: 110rem;
  }

  .main__buttons p {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .main__previous-price {
    font-weight: bold;
    text-decoration: line-through;
    margin-left: 0px;
  }

  .main__price-box {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;
  }

  .main__container-b {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 25px;
  }

  .main__p--discount {
    margin-bottom: 0;
    color: #ae0015;
    font-weight: bold;
    background-color: #ffede0;
    padding: 0.3rem 1.2rem;
    border-radius: 30px;
    text-align: center;
    cursor: pointer;
  }

  .main__p--discount.active {
    border: 2px solid #fff;
  }

  .main__current-price {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .main__number-box {
    height: 60px;
    width: 40% !important;
    display: flex;
    padding: 10px;
  }

  .main__buy-button {
    width: 60%;
    font-size: 16px;
    display: flex;
  }

  .galleryMobile .swiper-button-next,
  .galleryMobile .swiper-button-prev {
    object-fit: contain;
    height: 2rem !important;
    width: 2rem !important;
    border-radius: 0%;
  }

  .galleryMobile .swiper {
    width: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 768px) {
  .main__h1 {
    font-size: 1.2rem;
    margin-bottom: 0.7rem;
    margin-top: 1.5rem;
  }

  .main__p {
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 0px;
    padding-bottom: 11px;
    text-align: justify;
  }

  .main__p--discount {
    margin-bottom: 0;
    padding: 0.5rem 1rem;
    border-radius: 32px;
  }

  .main__buy-button {
    width: 50%;
    font-size: 16px;
    display: flex;
  }

  .galleryMobile .swiper-slide img {
    display: block;
    height: 100%;
    width: 85%;
    object-fit: cover;
  }

  .galleryMobile .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: var(--swiper-navigation-sides-offset, -5px);
    left: auto;
  }

  .galleryMobile .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: var(--swiper-navigation-sides-offset, -5px);
    right: auto;
  }
}
@media screen and (min-width: 768px) {
  .main__buttons .main__number-box {
    width: 35% !important;
  }
  .product-details .main__number-box {
    width: 50% !important;
  }
}
@media screen and (min-width: 920px) {
  .main__buttons .main__number-box {
    width: 25% !important;
  }
  .product-details .main__number-box {
    width: 50% !important;
  }
}
