.sang-section {
  padding: 4% 0% 0% 0%;
}
.sangmain {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 30px;
  background-color: #ffffff;
}
.sangmain h3 {
  font-size: 25px;
  font-weight: bold;
  color: #ae0015;
  margin: 10px 0px;
  text-align: center;
}
.sangmain h4 {
  font-size: 17px;
  font-weight: bold;
  line-height: 26px;
  color: #000000;
  margin: 12px 0px;
  text-align: center;
}
.sangmain ol {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  margin-bottom: 0px;
}
.sangmain ol li {
  font-size: 15px;
  line-height: 30px;
  padding: 2px 0px;
  text-align: justify;
}
.sangmain .checkbox-boxes-sang {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
@media only screen and (max-device-width: 768px) {
  .sangmain {
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    padding: 15px;
  }

  .sangmain h3 {
    font-size: 17px;
    margin: 5px 0px;
  }
  .sangmain h4 {
    font-size: 15px;
    line-height: 25px;
    margin: 5px 0px;
  }
  .sangmain ol {
    padding-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
  }
  .sangmain ol li {
    font-size: 13px;
    line-height: 23px;
    padding: 4px 0px;
  }
  .sangmain label b {
    font-size: 13px;
  }
  .sangmain .checkbox-boxes-sang {
    width: 14x;
    height: 14px;
    margin-right: 5px;
    margin-top: 7px;
  }
}
