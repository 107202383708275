.cart {
  margin: 4% 0px;
}

.hero-header-container .row {
  justify-content: center;
  text-align: center;
}

/* h1, h2, h3, h4, h5, h6 {
    color: #282828;
} */

.pr-30 {
  padding-right: 30px;
}

.page-padding {
  padding-top: 30px;
}

.hero-header .regular-title {
  padding: 4vh 0;
}

.shopping-cart-content {
  padding-top: 10px;
  padding-bottom: 2em;
}

.product-thumbnail {
  padding-right: 8px;
}

.product-thumbnail a {
  display: inline-block;
}

.product-thumbnail img {
  width: 100px;
  height: 100px;
  max-width: 100px;
  object-fit: cover;
}
.product-name-price a {
  font-size: 15px;
  line-height: 26px;
}

.product-name-price .price {
  font-size: 14px;
  font-weight: 600;
  margin-top: 7px;
}
.quantity {
  align-items: center;
  display: flex;
}

.quantity .minus,
.quantity .plus {
  align-items: center;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  outline: 0;
  overflow: visible;
  text-decoration: none;
  transition: background 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-user-select: none;
  user-select: none;
  width: 30px;
}

.quantity .qty {
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #282828;
  max-height: 24px;
  min-width: 30px;
  outline: 0;
  padding: 0;
  text-align: center;
  width: 20%;
}

.quantity .input-text,
.text {
  pointer-events: none;
}
.price {
  font-size: 17px;
  margin: 0;
  font-weight: 600;
}

.close {
  text-align: right;
}

.remove {
  border-radius: 100%;
  border: 0;
  height: 16px;
  width: 16px;
  display: block;
  position: relative;
  color: transparent;
  float: right;
  cursor: pointer;
}

.remove:before,
.remove:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background-color: #282828;
  transform-origin: 50% 50%;
  opacity: 1;
  -moz-transition: -moz-transform ease 0.25s;
  -webkit-transition: -webkit-transform ease 0.25s;
  -o-transition: -o-transform ease 0.25s;
  -ms-transition: -ms-transform ease 0.25s;
  transition: transform ease 0.25s;
}

.remove:before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.remove:after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.remove:before,
.remove:after {
  height: 1px;
}

.remove:hover:before,
.remove:hover:after {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}

/* coupon */

.form-row.float-label {
  position: relative;
  /* margin-top: 20px; */
  margin-bottom: 24px;
}

.coupon .form-row.float-label {
  position: relative;
}

.coupon .form-row {
  display: inline-block;
  margin: 0;
}

.coupon input {
  outline: 0;
  width: auto;
}

.coupon input,
.coupon .button {
  padding: 8px 20px;
  border-radius: 30px;
  border: 0;
}

/* Coupon */

/* cart-collaterals */
.cart-collaterals {
  color: currentColor;
  margin-bottom: 5vh;
}

.cart-collaterals h5,
.cart-collaterals h3,
.cart-collaterals h2 {
  /* font-size: 20px; */
  margin-bottom: 0;
  height: 40px;
}

.cart-totals {
  border: 1px solid currentColor;
  padding: 30px 30px;
}

.cart-totals hr {
  margin: 0;
}

.cart-totals > div {
  padding: 10px 0px;
  font-size: 14px;
  line-height: 27px;
}

.shipping-title {
  margin-bottom: 10px;
}

/* .cart-subtotal {
    margin-bottom: 0.5rem;
} */

.shipping-calculator {
  animation: 0.8s fadeInUp;
}

.shipping-destination {
  margin: 15px 0 20px;
}

.shipping-country {
  color: #282828;
}

.cart-collaterals .shipping-calculator-button {
  color: #282828;
}

.order-total {
  padding-bottom: 10px;
}

.order-total p {
  font-size: 14px;
}

.cart-collaterals .button.outlined {
  border: 2px solid currentColor;
}

.cart-collaterals .button.outlined,
.cart-collaterals .button.outlined:hover,
.cart-collaterals .button.outlined:focus,
.cart-collaterals .button.outlined:active {
  color: #282828;
  opacity: 1;
  background: none;
}

.cart-collaterals .button.outlined,
.cart-collaterals .button.outlined:hover,
.cart-collaterals .button.outlined:focus,
.cart-collaterals .button.outlined:active {
  color: #282828;
}

.cart-collaterals .update-button {
  padding: 0.575rem 1.75rem;
  /* font-size: .875em; */
}

.proceed-to-checkout {
  padding-top: 20px;
  overflow: hidden;
  margin-bottom: 60px;
}

.proceed-to-checkout .button {
  width: 100%;
  cursor: pointer;
}

/* cart-collaterals */

.cart .hero-header {
  margin-top: 120px;
}

.Shopping-btn .main__number-box {
  width: 100% !important;
  padding: 12px;
}
.border-dark {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
@media only screen and (max-width: 575px) {
  // .product-thumbnail {
  //   padding-right: 1px;
  // }

  .product-thumbnail img {
    width: 80px;
    height: 80px;
  }

  .product-subtotal {
    flex-direction: column-reverse !important;
  }

  .coupon {
    flex-direction: column;
    padding: 30px 0px;
  }

  .coupon div {
    width: 100%;
    margin-right: 0 !important;
    margin-bottom: 10px !important;
  }

  .coupon input {
    width: 100%;
  }

  .coupon button {
    width: 100%;
  }

  .cart-totals {
    padding: 30px 20px 0;
  }

  .cart-totals h5 {
    text-align: center;
  }
  .Shopping-btn .main__number-box {
    width: 100% !important;
    padding: 10px;
  }
}

// @media only screen and (min-width: 768px) {
//   .Shopping-btn .main__number-box {
//     width: 100% !important;
//     padding:12px 10px;
//   }
// }

@media only screen and (min-width: 1200px) {
  .cart-items {
    padding-right: 30px;
  }
}

.empty-cart-list {
  margin-top: 100px;
  margin-bottom: 200px;
  text-align: center;
}

.empty-cart-list img {
  width: 20%;
  display: block;
  margin: auto;
}

.empty-cart-list p {
  text-align: center;
}
