@import '../../Css/variable.scss';
.contact {
  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    border-radius: 10px;
    overflow: hidden;
  }
  &-info {
    background-color: $primary;
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    justify-content: start;
    padding: 40px 30px;
    width: 40%;
    h2 {
      font-size: 24px;
      color: #ffffff;
      line-height: 34px;
      margin: 0 0 25px;
    }
    .location-info {
      li {
        position: relative;
        padding-left: 50px;
        margin-bottom: 35px;
        &:last-child {
          margin-bottom: 0;
        }
        h3 {
          font-weight: 700;
          font-size: 16px;
          line-height: 30px;
          margin: 0 0 7px;
          color: #ffffff;
        }
        i {
          position: absolute;
          top: 50%;
          left: 0;
          color: $white;
          font-size: 32px;
          transform: translateY(-50%);
        }
        img {
          position: absolute;
          top: 50%;
          left: 0;
          width: 32px;
          height: 32px;
          transform: translateY(-50%);
        }
        p {
          font-size: 14px;
          font-weight: bold;
          color: #ffffff;
          margin: auto;
          line-height: 30px;
        }
        a {
          padding-left: 0;
          font-weight: normal;
          font-size: 14px;
        }
      }
    }
  }
  &-form {
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    padding: 40px 30px;
    background: #f5f5f5;
    border: 1px solid #d9d9d9;
    form {
      width: 100%;
    }
  }
}
.contactmap {
  padding-top: 30px;
  iframe {
    width: 100%;
    height: 400px;
    border-radius: 8px;
    overflow: hidden;
  }
}

@media only screen and (max-width: 991px) {
  .contact-form,
  .contact-info {
    width: 100%;
    padding: 30px;
  }
}

.contactus-banner {
  background-image: url('../../Assets/Images/Contact_us/contact.jpg');
}

.yagna-booking {
  background-image: url('../../Assets/Images/Contact_us/yagna.jpg');
}
.yagna-booking {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  &:before {
    content: '';
    background-color: $black;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
.contact-page {
  margin: 4% 0%;
}
.contact-btn-red {
  display: flex;
  align-items: center;
}
.contact-btn-red span {
  margin-right: 20px;
  display: flex;
  align-items: center;
  padding: 3px 0px;
}
.contact-btn-red span input[type='radio'] {
  accent-color: #ae0015 !important;
}

.contact-btn-red .radio {
  width: 18px;
  margin-right: 5px;
  height: 18px;
  margin-top: 0px;
  margin-bottom: 0px;
}

// new css for Responsive
.question-star {
  margin-top: 10px;
  margin-bottom: 5px;
}
.question-star p {
  margin: 0px;
}
.question-star span {
  padding-left: 0;
  font-weight: normal;
  font-size: 25px !important;
  line-height: 30px;
  margin-bottom: 0px;
}
@media only screen and (max-width: 768px) {
  .contactmap iframe {
    width: 100%;
    height: 300px;
    border-radius: 8px;
    overflow: hidden;
  }

  .contact-form,
  .contact-info {
    width: 100%;
    padding: 20px;
  }
  .contact-info .location-info li p {
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    margin: 5px 0px;
  }
  .contact-info .location-info li {
    position: relative;
    padding-left: 50px;
    margin-bottom: 20px;
  }

  .contact-btn-red {
    display: block;
    align-items: center;
  }
}
