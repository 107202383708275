.news {
  margin: 4% 0%;
}

.news-1 {
  margin-top: 4%;
  margin-bottom: 4%;
}

.servicebox-hed {
  background-color: #ffffff;
  margin-bottom: 1px;
  border-radius: 10px;
  padding: 20px;
  height: 125px;
  display: flex;
  align-items: center;
  margin: 10px 0px;
}

.servicebox-hed h5 {
  font-size: calc(11px + 0.390625vw);
  color: #ae0015;
  line-height: 30px;
  margin: 0px;
}

.servicebox-hed-text h2 {
  font-size: calc(15px + 0.390625vw);
  font-weight: bold;
  color: #ae0015;
  line-height: 24px;
  margin-bottom: 20px;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .news .servicebox-hed .btn-group a {
    font-size: calc(12px + 0.4vw);
  }
  .news .servicebox-hed {
    height: auto;
  }
}
