.bhadarvi-poonam-banner {
  background-image: url('../../../Assets/Images/Bhadarvi_poonam/ambaji-night-badarvi.jpg');
}
.bhadarvi-poonam {
  margin: 4% 0%;
}
.bhadarvi-poonam .card {
  padding: 30px;
}

/* ------------------------- payment success CSS */
.bhadarvi-poonam-success {
  margin: 10px auto;
  max-width: 700px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}

.bhadarvi-poonam-success .check-icon {
  display: flex;
  justify-content: center;
}

.bhadarvi-poonam-success .check-icon svg {
  width: 80px;
  height: 80px;
  color: green;
}

/* payment success CSS ------------------------- */
