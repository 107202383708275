.gold-banner {
  background-image: url("../../../Assets/Images/Donation/DonationGold.jpg");
}

.seva-donatemain {
  margin: 4% 0%;
}
.gold-donation {
  margin: 4% 0%;
}

.gold-donation .card {
  padding: 20px;
}

@import "../../../Css/variable.scss";
.donation-section {
  padding: 110px 0 70px;
}
.donation-title h3 {
  border-bottom: 1px solid $gray;
  padding-bottom: 21px;
}
.donation-wrapper {
  padding: 8px 0 30px;
}
.donation-btn .orange-whitebtn {
  margin-right: 20px;
}
.donatemain {
  border: 1px solid $gray;
  border-radius: 10px;
  padding: 30px 25px;
  background-color: $white;
}
.amunt-option .flexinner {
  display: flex;
  flex-wrap: wrap;
}
.amunt-option .flexinner .amunt-radio {
  margin: 0 10px 10px 0;
}
.amunt-radio input {
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
}
.amunt-radio span {
  border: 1px solid $gray;
  font-weight: normal;
  font-size: 14px;
  border-radius: 30px;
  padding: 7px 20px;
  cursor: pointer;
  display: inline-block;
}
.amunt-radio input[type="radio"]:checked + span {
  border-color: $primary;
  background-color: $primary;
  color: $white;
}
.identity-option .flexinner {
  display: flex;
  flex-wrap: wrap;
}
.identity-option .flexinner .custom-radio {
  margin: 0 20px 10px 0;
}
.custom-radio input {
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
}
.custom-radio span {
  font-weight: normal;
  font-size: 14px;
  padding-left: 23px;
  cursor: pointer;
  display: inline-block;
  position: relative;
}
.custom-radio span:before {
  content: "";
  height: 16px;
  width: 16px;
  border: 1px solid $gray;
  position: absolute;
  left: 0;
  top: 2px;
  border-radius: 100%;
}
.custom-radio input[type="radio"]:checked + span:after {
  background-color: $primary;
  content: "";
  height: 12px;
  width: 12px;
  position: absolute;
  left: 2px;
  top: 4px;
  border-radius: 100%;
}
.donateform {
  padding-right: 80px;
}
.donateimg {
  position: relative;
  padding-left: 74px;
}
.donateimg-inner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  max-width: 224px;
  border: 4px solid $white;
}

@media only screen and (max-width: 991px) {
}
@media only screen and (max-width: 991px) {
  .imgorder {
    order: -1;
  }
  .donateimg {
    max-width: 500px;
    margin: 0 auto 30px;
  }
  .donateform {
    padding-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .donateimg-inner {
    max-width: 140px;
  }
}
.alter-box {
  padding: 50px;
}
