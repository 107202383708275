.shopOnline-banner {
  background-image: url('../../../Assets/Images/E_store/AmbajiTemple_ECommerceHeader.png');
}
.shop-page {
  margin: 4% 0%;
}
.shop-page .societybox .societybox-body {
  padding: 20px 25px;
}
.button-row {
  display: flex;
  justify-content: space-between; /* Push buttons to the start and end of the row */
  align-items: center; /* Optional: Vertically align buttons if needed */
}

/* Add to cart button styles */
.outlinebtn {
  // background-color: transparent;
  // color: #ae0015;
  // border: 2px solid #ae0015;
  // padding: 10px 20px;
  // cursor: pointer;
  // border-radius: 5px;
  // border-radius: 30px;

  // transition: background-color 0.3s ease, color 0.3s ease;
  background-color: transparent;
  border: 2px solid #ae0015;
  color: #ae0015;
  display: inline-block;
  cursor: pointer;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 20px;
  &:hover {
    background-color: #fff;
    color: #ae0015;
  }
}

.outlinebtn:hover {
  background-color: #ae0015;
  color: white;
}
.shopping-button {
  width: 100%;
  margin-right: 5%;
}
.cart-button {
  width: 90%;
}

.shopping-heading {
  width: 75%;
  display: inline-block;
  overflow-wrap: break-word;
  white-space: normal;
  margin-top: 0px;
}

@media only screen and (max-width: 768px) {
  .shopping-heading {
    width: 100%;
    display: inline-block;
    overflow-wrap: break-word;
    white-space: normal;
    margin-top: 0px;
  }
}
