/* Vertical Tab CSS */
.section__Jobs-container {
  margin-top: 4%;
  margin-bottom: 4%;
  position: relative;
  background-color: #fff;
  padding: 30px;
  min-height: 65vh;
  overflow: hidden;
}

.section__Jobs-styledTabList {
  max-width: 300px;
}

.section__Jobs-styledTabList {
  padding: 0;
}

/* Vertical Tab Btn CSS */
.section__Jobs-buttonCompany {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  border-left: 2px solid #8892b0;
  outline: none;
  height: 40px;
  width: 150px;
  text-align: left;
  padding: 0 20px;
}

.section__Jobs-buttonCompany:hover,
.section__Jobs-buttonCompany:focus {
  background-color: #ae0015;
  color: #fff !important;
}

.section__Jobs-buttonCompany:hover,
.section__Jobs-buttonCompany:active,
.section__Jobs-buttonCompany:focus {
  color: #64ffda;
  outline: 0px;
}

.ReactModal__Content {
  max-width: 600px;
  width: 95%;
  max-height: 90vh;
  position: absolute;
  inset: 50% auto auto 50%;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: hidden;
  border-radius: 4px;
  outline: none;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.ReactModal__Overlay {
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.55) !important;
}

.address-details .btn-outline-danger,
.address-details .btn-outline-success {
  font-size: 13px;
  padding: 5px 10px;
  margin-top: 10px;
}

.address-details .card p {
  font-size: 15px;
}

/* Transaction CSS */
.react-tabs__tab-panel {
  margin-top: 30px;
}
.transaction table {
  width: 100%;
  border: 1px solid grey;
}

.transaction table th,
.transaction table td {
  border: 1px solid grey;
  padding: 5px 7px;
  align-items: center;
  text-align: center;
}

.transaction table tr td {
  border: 1px solid grey;
  padding: 5px 7px;
  align-items: center;
  text-align: center;
  /* overflow-x:auto; */
}
@media screen and (max-width: 1023px) {
  .section__Jobs-styledTabList {
    display: inline-flex;
  }
}
@media screen and (max-width: 1024px) {
  .section__Jobs-buttonCompany {
    padding: 0px 20px 0px 20px !important;
    border-left: none !important;
  }

  .section__Jobs-styledTabList {
    max-width: 100%;
  }
}
@media screen and (max-width: 771px) {
  .section__Jobs-styledTabList {
    display: inline-flex;
    overflow-x: scroll;
  }
  .section__Jobs-buttonCompany {
    padding: 2px 12px 2px 12px !important;
    border-left: none !important;
    font-size: 14px;
    // width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .table-responsive .whitebtn {
    font-size: 13px;
  }
  .table-responsive .orangebtn {
    font-size: 13px;
  }
  .transaction .react-tabs__tab {
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 10px 18px;
    cursor: pointer;
    font-size: 15px;
  }

  .user-profile .form-group label {
    font-size: 14px;
    font-weight: 600;
    margin: 10px 0 5px;
  }
  .user-profile .form-group .form-control {
    height: 40px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    color: #333333;
    box-shadow: none;
    font-size: 14px;
  }
  .address-details .card p {
    font-size: 14px;
  }
}
