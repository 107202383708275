/* ------------------------- payment success CSS */
.money-donation-success {
  margin: 10px auto;
  max-width: 700px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}

.money-donation-success .check-icon {
  display: flex;
  justify-content: center;
}

.money-donation-success .check-icon svg {
  width: 80px;
  height: 80px;
  color: green;
}

/* payment success CSS ------------------------- */

/* ------------------------- payment failed CSS */
.money-donation-failed {
  max-width: 500px;
  margin: 10px auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}

.money-donation-failed .check-icon {
  display: flex;
  justify-content: center;
}

.money-donation-failed .check-icon svg {
  width: 80px;
  height: 80px;
  color: #ae0015;
}

/* payment failed CSS ------------------------- */

/* print payment receipt CSS ------------------ */
#printable {
  display: none;
}

.print-payment-status {
  background-color: #ffd801;
}

@media print {
  #non-printable {
    display: none;
  }

  #printable {
    display: block;
    width: 75%;
  }
}
