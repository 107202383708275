@import './variable.scss';

header {
  padding: 15px 0;
  // background-color: #ae0015;
  background-image: linear-gradient(#ae0015, #ae001594);
}
header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  transition: 0.3s all;

  .whitebtn {
    border-color: $primary;
    // background-color: $primary;
    background-color: #ffffff;
    // color: $white;
    color: #ae0015;

    &:hover {
      // border-color: $gray-dark;
      border-color: #ffd801;
      // background-color: $gray-dark;
      background-color: #ffd801;
      color: $white;
    }
  }

  nav ul {
    margin: 0;
    padding: 0;
    display: flex;

    li {
      list-style: none;
      margin-right: 40px;
      position: relative;

      a {
        // color: $gray-dark;
        color: #ffffff;
        position: relative;
        padding: 8px 0;
        text-transform: capitalize;

        &:before {
          content: '';
          height: 2px;
          width: 0;
          position: absolute;
          left: 0;
          bottom: 0;
          background-color: #ffd801;
          transition: 0.4s all;
        }
      }

      &:hover {
        color: #ffd801;
      }

      &:hover,
      &.active {
        a {
          content: '';
          border-bottom: 0px solid #ffd801;
          color: #ffd801;
          // &:before {
          //   width: 100%;
          // }
        }
      }
    }
  }

  &.fixed {
    position: fixed;
  }
}

header nav ul li a.active {
  content: '';
  border-bottom: 2px solid #ffd801;
  color: #ffd801 !important;
}

header .container {
  max-width: 100%;
}

.header-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.header-logo {
  width: 60px;
}

.header-right {
  // width: calc(100% - 60px);
  width: calc(100% - 100px);

  padding-left: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.toggle {
  cursor: pointer;
  overflow: hidden;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0px;
  display: none;
  align-items: center;
  justify-content: center;
}

.get-support {
  position: relative;
  color: $gray-dark;
  padding: 0 30px 0 37px;

  i {
    position: absolute;
    left: 0;
    top: 13px;
    font-size: 24px;
    // color: $primary;
  }

  span {
    font-weight: 300;
    font-size: 14px;
    display: block;
  }

  a {
    margin: 2px 0 0;
    display: block;
    // color: inherit;
    color: $white;
    font-weight: 600;

    &:hover {
      color: $secondary;
    }
  }
}

.line-toggle {
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: $white;
  border-radius: 5px;
  transition: all 0.6s;

  &:first-child {
    transform: translateY(-12px);
    transition-delay: 0s;
  }

  &:nth-child(2) {
    transition: all 0.6s;
    opacity: 1;
  }

  &:last-child {
    transform: translateY(12px);
    transition-delay: 0s;
  }
}

.close-line-toggle {
  width: 25px;

  &:first-child {
    transform: rotate(-45deg);
  }

  &:nth-child(2) {
    transition: all 0.6s;
    opacity: 0;
  }

  &:last-child {
    transform: rotate(45deg);
  }
}

.menuactive .toggle {
  .line-toggle {
    &:first-child {
      background: $primary;
      transform: translateY(0) translateX(0) rotate(45deg);
      transition-delay: 0.1s;
    }

    &:nth-child(2) {
      transform: translateX(110px);
      transition-delay: 0s;
    }

    &:last-child {
      background: $primary;
      transform: translateY(0) translateX(0) rotate(314deg);
      transition-delay: 0.1s;
    }
  }
}

@media only screen and (max-width: 1200px) {
  header nav ul li {
    list-style: none;
    margin-right: 15px;

    a {
      font-size: 13px;
    }
  }

  .get-support {
    display: none;
    padding: 0 10px;
    display: none;

    a {
      height: 40px;
      width: 40px;
      background-color: $primary;
      border-radius: 100%;
      font-size: 0;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        position: absolute;
        width: 18px;
        font-size: 24px;
        left: inherit;
        top: 10px;
      }

      i {
        position: initial;
        font-size: 20px;
        color: $white !important;
      }

      span {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .toggle {
    display: flex;
    margin-left: 20px;
  }

  .header-right nav {
    position: fixed;
    top: -200%;
    left: 0;
    right: 0;
    padding: 30px;
    text-align: center;
    background-color: $primary;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: 0.5s all;
    height: 100%;
    overflow-y: scroll;
  }

  .header-right nav.toggle_open {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 30px;
    text-align: center;
    background-color: $primary;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transition: 0.5s all;
    height: 100%;
    overflow: hidden;
    z-index: 999;
  }

  .header-right nav.toggle_open::after {
    position: relative;
    top: -200;
    left: 0;
    right: 0;
  }

  .menuactive .header-right nav {
    top: 88px;
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  header nav ul {
    flex-flow: column;

    li {
      margin: 0 0 5px;
      text-align: left;

      a {
        font-size: 16px;
        color: $white !important;
        padding: 10px 0px;
        display: inline-block;

        &:before {
          background-color: $yellow;
        }
      }

      &.active a {
        color: $yellow !important;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .header-right {
    padding-left: 0px;
  }
}

.custom-dropdown {
  position: relative;
  padding: 5px 0;
}

.dropdown,
.dropdown-center,
.dropend,
.dropstart,
.dropup,
.dropup-center {
  position: relative;
}

.custom-dropdown .dropdown {
  position: absolute;
  width: 220px;
  top: 200%;
  opacity: 0;
  transition: 0.3s all;
  right: 0;
  border-radius: 5px;
  background-color: #ae0015;
}

.custom-dropdown:hover .dropdown,
.custom-dropdown:focus .dropdown,
.custom-dropdown.openmenu .dropdown {
  top: 100%;
  opacity: 1;
}

.custom-dropdown .dropdown ul {
  margin: 0;
  padding: 10px 0;
}

.custom-dropdown .dropdown ul li {
  list-style: none;
}

.custom-dropdown .dropdown ul li a {
  color: #ffffff;
  padding: 10px 30px;
  display: flex;
}

.active {
  color: $secondary;
  /* Add your desired active styles here */
}

.get-support-1 {
  display: none;
}

.button-close {
  display: none !important;
}

.get-support img {
  position: absolute;
  left: 0;
  top: 13px;
  font-size: 24px;
}

.blob {
  display: inline-block;
  border-radius: 50%;
  margin: 3px;
  height: 10px;
  width: 10px;
  transform: scale(1);
  margin-top: 2px;
  background: rgb(255 0 0);
  box-shadow: 0 0 0 0 rgb(255 0 0);
  animation: pulse-red 2s ease-in-out infinite;
}
@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

@media only screen and (max-width: 991px) {
  .get-support-1 {
    display: inline-block !important;
  }

  .get-support-1 {
    margin-top: 15px;
  }

  .get-support-1 span {
    display: block;
  }

  .get-support-1 {
    position: relative;
    color: #333333;
    padding: 0 0 0 40px;
  }

  .get-support-1 img {
    position: absolute;
    left: 0;
    top: 13px;
    font-size: 24px;
  }

  .get-support-1 a {
    margin: 2px 0 0;
    display: block;
    text-align: left;
    color: #ffffff;
  }

  .get-support-1 a:hover {
    color: #ffd801;
  }

  .button-close {
    display: inline-block !important;
  }

  .toggle {
    display: flex;
    margin-left: 5px;
    margin-right: 5px;
    color: #fff !important;
    z-index: 1000;
  }

  header nav ul li span {
    display: inline !important;
  }
  .goog-te-gadget-simple {
    padding: 5px 15px 7px !important;
    border-radius: 50px;
    margin-top: 10px;
    line-height: 23px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1400px) {
  header nav ul li {
    list-style: none;
    margin-right: 30px;
    position: relative;
    font-size: 14px;
  }
}

// @import "./variable.scss";

// .logout-modal{
//     top: 50%;
//     margin-right: -50%;
//     left: 50%;
//     right: auto;
//     bottom: auto;
//     max-width: 400px;
//     padding: 0px;
//     border: none;
//     transform: translate(-50%, -50%);
// }

// header {
//   // padding: 15px 0;
//   // background-color: #ae0015;
//   // background-image: linear-gradient(#ae0015, #ae001594);
// }

// header {
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   z-index: 999;
//   transition: 0.3s all;

//   .whitebtn {
//     border-color: $primary;
//     // background-color: $primary;
//     background-color: #ffffff;
//     // color: $white;
//     color: #ae0015;

//     &:hover {
//       // border-color: $gray-dark;
//       border-color: #ffd801;
//       // background-color: $gray-dark;
//       background-color: #ffd801;
//       color: $white;
//     }
//   }

//   nav ul {
//     margin: 0;
//     padding: 0;
//     display: flex;

//     li {
//       list-style: none;
//       margin-right: 40px;
//       position: relative;

//       a {
//         // color: $gray-dark;
//         color: #ffffff;
//         position: relative;
//         padding: 8px 0;
//         text-transform: capitalize;

//         &:before {
//           content: "";
//           height: 2px;
//           width: 0;
//           position: absolute;
//           left: 0;
//           bottom: 0;
//           background-color: #ffd801;
//           transition: 0.4s all;
//         }
//       }

//       &:hover {
//         color: #ffd801;
//       }

//       &:hover,
//       &.active {
//         a {
//           content: "";
//           border-bottom: 0px solid #ffd801;
//           color: #ffd801;
//           // &:before {
//           //   width: 100%;
//           // }
//         }
//       }
//     }
//   }

//   &.fixed {
//     position: fixed;
//   }
// }

// header nav ul li a.active {
//   content: "";
//   border-bottom: 2px solid #ffd801;
//   color: #ffd801 !important;
// }

// // header .container {
// //   max-width: 100%;
// // }

// .header-wrapper {
//   display: flex;
//   flex-wrap: wrap;
// }

// .header-logo {
//   width: 60px;
// }

// .header-right {
//   width: calc(100% - 60px);
//   padding-left: 50px;
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
//   flex-wrap: wrap;
// }

// .toggle {
//   cursor: pointer;
//   overflow: hidden;
//   width: 40px;
//   height: 40px;
//   position: absolute;
//   right: 0px;
//   display: none;
//   align-items: center;
//   justify-content: center;
// }

// .get-support {
//   position: relative;
//   color: $gray-dark;
//   padding: 0 30px 0 37px;

//   i {
//     position: absolute;
//     left: 0;
//     top: 13px;
//     font-size: 24px;
//     // color: $primary;
//   }

//   span {
//     font-weight: 300;
//     font-size: 14px;
//     display: block;
//   }

//   a {
//     margin: 2px 0 0;
//     display: block;
//     // color: inherit;
//     color: $white;
//     font-weight: 600;

//     &:hover {
//       color: $secondary;
//     }
//   }
// }

// .line-toggle {
//   position: absolute;
//   display: block;
//   width: 40px;
//   height: 3px;
//   background: $white;
//   border-radius: 5px;
//   transition: all 0.6s;

//   &:first-child {
//     transform: translateY(-12px);
//     transition-delay: 0s;
//   }

//   &:nth-child(2) {
//     transition: all 0.6s;
//     opacity: 1;
//   }

//   &:last-child {
//     transform: translateY(12px);
//     transition-delay: 0s;
//   }
// }

// .close-line-toggle {
//   width: 25px;

//   &:first-child {
//     transform: rotate(-45deg);
//   }

//   &:nth-child(2) {
//     transition: all 0.6s;
//     opacity: 0;
//   }

//   &:last-child {
//     transform: rotate(45deg);
//   }
// }

// .menuactive .toggle {
//   .line-toggle {
//     &:first-child {
//       background: $primary;
//       transform: translateY(0) translateX(0) rotate(45deg);
//       transition-delay: 0.1s;
//     }

//     &:nth-child(2) {
//       transform: translateX(110px);
//       transition-delay: 0s;
//     }

//     &:last-child {
//       background: $primary;
//       transform: translateY(0) translateX(0) rotate(314deg);
//       transition-delay: 0.1s;
//     }
//   }
// }

// @media only screen and (max-width: 1300px) {
//   header nav ul li {
//     list-style: none;
//     margin-right: 25px;
//   }
// }

// @media only screen and (max-width: 1200px) {
//   header nav ul li {
//     list-style: none;
//     margin-right: 15px;

//     a {
//       font-size: 14px;
//     }
//   }

//   .get-support {
//     display: none;
//     padding: 0 10px;
//     display: none;

//     a {
//       height: 40px;
//       width: 40px;
//       background-color: $primary;
//       border-radius: 100%;
//       font-size: 0;
//       position: relative;
//       display: flex;
//       align-items: center;
//       justify-content: center;

//       img {
//         position: absolute;
//         width: 18px;
//         font-size: 24px;
//         left: inherit;
//         top: 10px;
//       }

//       i {
//         position: initial;
//         font-size: 20px;
//         color: $white !important;
//       }

//       span {
//         display: none;
//       }
//     }
//   }
// }

// @media only screen and (max-width: 991px) {
//   .toggle {
//     display: flex;
//     margin-left: 20px;
//   }

//   .header-right nav {
//     position: fixed;
//     top: -200%;
//     left: 0;
//     right: 0;
//     padding: 30px;
//     text-align: center;
//     background-color: $primary;
//     opacity: 0;
//     visibility: hidden;
//     pointer-events: none;
//     transition: 0.5s all;
//     height: 100%;
//     overflow-y: scroll;
//   }

//   .header-right nav.toggle_open {
//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     padding: 30px;
//     text-align: center;
//     background-color: $primary;
//     opacity: 1;
//     visibility: visible;
//     pointer-events: auto;
//     transition: 0.5s all;
//     height: 100%;
//     overflow: hidden;
//     z-index: 999;
//   }

//   .header-right nav.toggle_open::after {
//     position: relative;
//     top: -200;
//     left: 0;
//     right: 0;
//   }

//   .menuactive .header-right nav {
//     top: 88px;
//     opacity: 1;
//     visibility: visible;
//     pointer-events: all;
//   }

//   header nav ul {
//     flex-flow: column;

//     li {
//       margin: 0 0 5px;
//       text-align: left;

//       a {
//         font-size: 16px;
//         color: $white !important;
//         padding: 10px 0px;
//         display: inline-block;

//         &:before {
//           background-color: $yellow;
//         }
//       }

//       &.active a {
//         color: $yellow !important;
//       }
//     }
//   }
// }

// @media only screen and (max-width: 767px) {
//   .header-right {
//     padding-left: 0px;
//   }
// }

// .custom-dropdown {
//   position: relative;
//   padding: 5px 0;
// }

// .dropdown,
// .dropdown-center,
// .dropend,
// .dropstart,
// .dropup,
// .dropup-center {
//   position: relative;
// }

// .custom-dropdown .dropdown {
//   position: absolute;
//   width: 220px;
//   top: 200%;
//   opacity: 0;
//   transition: 0.3s all;
//   right: 0;
//   border-radius: 5px;
//   background-color: #ae0015;
// }

// .custom-dropdown:hover .dropdown,
// .custom-dropdown:focus .dropdown,
// .custom-dropdown.openmenu .dropdown {
//   top: 100%;
//   opacity: 1;
// }

// .custom-dropdown .dropdown ul {
//   margin: 0;
//   padding: 10px 0;
// }

// .custom-dropdown .dropdown ul li {
//   list-style: none;
// }

// .custom-dropdown .dropdown ul li a {
//   color: #ffffff;
//   padding: 10px 30px;
//   display: flex;
// }

// .active {
//   color: $secondary;
//   /* Add your desired active styles here */
// }

// .get-support-1 {
//   display: none;
// }

// .button-close {
//   display: none !important;
// }

// .get-support img {
//   position: absolute;
//   left: 0;
//   top: 13px;
//   font-size: 24px;
// }

// @media only screen and (max-width: 991px) {
//   .get-support-1 {
//     display: inline-block !important;
//   }

//   .get-support-1 {
//     margin-top: 15px;
//   }

//   .get-support-1 span {
//     display: block;
//   }

//   .get-support-1 {
//     position: relative;
//     color: #333333;
//     padding: 0 0 0 40px;
//   }

//   .get-support-1 img {
//     position: absolute;
//     left: 0;
//     top: 13px;
//     font-size: 24px;
//   }

//   .get-support-1 a {
//     margin: 2px 0 0;
//     display: block;
//     text-align: left;
//     color: #ffffff;
//   }

//   .get-support-1 a:hover {
//     color: #ffd801;
//   }

//   .button-close {
//     display: inline-block !important;
//   }

//   .toggle {
//     display: flex;
//     margin-left: 5px;
//     margin-right: 5px;
//     color: #fff !important;
//     z-index: 1000;
//   }

//   header nav ul li span {
//     display: inline !important;
//   }
//   .goog-te-gadget-simple {
//     padding: 5px 15px 7px !important;
//     border-radius: 50px;
//     margin-top: 10px;
//     line-height: 23px;
//   }
// }
