.table-responsive-box {
  background-color: #fff;
  padding: 15px;
  border-radius: 7px;
}
.table-responsive-box h3 {
  margin: 0px;
  font-size: 25px;
  padding: 15px 10px;
  font-weight: 900;
  color: #ae0015;
}
.table-responsive-box h4 {
  margin: 0px;
  font-size: 18px;
  padding: 10px 10px;
  color: #000;
}
.table-responsive-box td {
  border: 1px solid #000;
  padding: 7px 10px;
  color: #333;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .table-responsive-box {
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
  }
  .table-responsive-box h3 {
    font-size: 17px;
    padding: 10px 3px;
    line-height: 27px;
    font-weight: 900;
    color: #ae0015;
  }
  .table-responsive-box h4 {
    font-size: 15px;
    padding: 5px 3px;
    line-height: 26px;
    color: #000;
  }
  .table-responsive-box td {
    border: 1px solid #000;
    padding: 7px 10px;
    font-size: 12px;
  }
}
