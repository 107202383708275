.sang-camp {
  padding: 4% 0% 0% 0%;
}

.sang-camp-box {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 30px;
  background-color: #ffffff;
}
.sang-camp-box label {
  font-size: 15px;
  font-weight: 600;
  line-height: 28px;
  margin: 0 0 7px;
}
.sang-camp-box .checkbox-boxes-sang {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
@media only screen and (max-device-width: 768px) {
  .sang-camp-box {
    padding: 15px;
  }
  .sang-camp h3 {
    font-size: 17px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .sang-camp-box {
    padding: 15px;
  }
  .sang-camp-box label {
    font-size: 14px !important;
    line-height: 26px;
    margin: 0 0 5px;
  }
  .sang-camp-box .checkbox-boxes-sang {
    width: 14x;
    height: 14px;
    margin-right: 5px;
    margin-top: 7px;
  }
}
