@import './variable.scss';

@font-face {
  font-family: 'ambaji';
  src: url('../Assets/Fonts/ambaji.eot?fklgvr');
  src: url('../Assets/Fonts/ambaji.eot?fklgvr#iefix')
      format('embedded-opentype'),
    url('../Assets/Fonts/ambaji.ttf?fklgvr') format('truetype'),
    url('../Assets/Fonts/ambaji.woff?fklgvr') format('woff'),
    url('../Assets/Fonts/ambaji.svg?fklgvr#ambaji') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[className^='iicon-'],
[className*=' iicon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ambaji' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iicon-icon-1:before {
  content: '\e900';
}

.iicon-icon-2:before {
  content: '\e901';
}

.iicon-icon-3:before {
  content: '\e902';
}

.iicon-icon-4:before {
  content: '\e903';
}

.iicon-icon-5:before {
  content: '\e904';
}

.iicon-icon-6:before {
  content: '\e905';
}

.iicon-icon-7:before {
  content: '\e906';
}

.iicon-icon-8:before {
  content: '\e907';
}

.iicon-icon-9:before {
  content: '\e908';
}

.iicon-icon-10:before {
  content: '\e909';
}

.iicon-icon-11:before {
  content: '\e90a';
}

.iicon-icon-12:before {
  content: '\e90b';
}

.iicon-icon-13:before {
  content: '\e90c';
}

.iicon-icon-14:before {
  content: '\e90d';
}

.iicon-icon-15:before {
  content: '\e90e';
}

.iicon-icon-16:before {
  content: '\e90f';
}

.iicon-icon-17:before {
  content: '\e910';
}

.iicon-icon-18:before {
  content: '\e911';
}

.iicon-icon-19:before {
  content: '\e912';
}

.iicon-icon-20:before {
  content: '\e913';
}

.iicon-icon-21:before {
  content: '\e914';
}

.iicon-icon-22:before {
  content: '\e915';
}

.iicon-icon-23:before {
  content: '\e916';
}

.iicon-icon-24:before {
  content: '\e917';
}

.iicon-icon-25:before {
  content: '\e918';
}

.iicon-icon-26:before {
  content: '\e919';
}

.iicon-icon-27:before {
  content: '\e91a';
}

.iicon-icon-28:before {
  content: '\e91b';
}

@import './variable.scss';

a {
  text-decoration: none !important;
  color: $primary;
}

a:hover {
  color: $primary;
}

img {
  max-width: 100%;
}

body {
  background-color: $secondary;
  font-family: 'Poppins', sans-serif;
  color: $gray-dark;
  font-size: 16px;
  line-height: 1.5;
  overflow-x: hidden;
}

h1 {
  font-size: 64px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 0 15px;
}

h2 {
  color: $black;
  font-size: 32px;
  font-weight: bold;
  margin: 0 0 25px;
}

p {
  margin: 0 0 16px;
  //   &:last-child {
  //     margin: 5px 0px 0px 0px;
  //   }
}

h2 span {
  color: $primary;
}

h3 {
  font-size: 24px;
  font-weight: bold;
  color: $black;
  margin-bottom: 20px;
}
h3 span {
  color: $primary;
}
h4 {
  font-size: 20px;
  font-weight: 600;
  margin: 10px 0 15px;
}

h5 {
  font-size: 16px;
  font-weight: bold;
  color: $primary;
}

.servicebox h5 {
  font-size: 16px;
  font-weight: bold;
  color: $primary;
  text-transform: capitalize;
  margin-bottom: 7px;
}

.footerbox h5 {
  font-size: 17px;
  font-weight: bold;
  color: #ae0015;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.societybox-body h5 {
  font-size: 16px;
  font-weight: bold;
  color: $primary;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.readlink {
  font-weight: 600;
  font-size: 14px;
  position: relative;
  margin-right: 25px;
  display: inline-block;
  border-bottom: 1px solid $primary;
  line-height: 1;
  cursor: pointer;

  &:hover {
    i {
      right: -35px;
    }
  }

  i {
    position: absolute;
    right: -25px;
    font-size: 18px;
    top: 0;
    transition: 0.4s all;
  }
}

// section {
//   // padding: 100px 0;
// }

.orangebtn {
  background-color: $primary;
  border: 2px solid $primary;
  color: $white;
  display: inline-block;
  cursor: pointer;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 20px;
  &:hover {
    background-color: $white;
    color: $primary;
  }
}

.orangebtn:hover {
  background-color: #fff;
  border: 2px solid #ae0015;
  color: #ae0015;
}

.whitebtn {
  background-color: $white;
  border: 2px solid $primary;
  color: $primary;
  display: inline-block;
  text-transform: capitalize;
  cursor: pointer;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 20px;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: $primary;
    border-color: $primary;
    color: $white;
  }
}

.white-btn {
  background-color: transparent;
  border: 2px solid $white;
  color: $white;
  display: inline-block;
  cursor: pointer;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
  padding: 7px 15px 6px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: $white;
    border-color: $white;
    color: $primary;
  }
}

.yellowbtn {
  background-color: $yellow;
  border: 2px solid $yellow;
  color: $white;
  display: inline-block;
  cursor: pointer;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 20px;
}

.yellowbtn:hover {
  background-color: $white;
  color: $yellow;
}

.themebtn {
  background-color: $secondary;
  border: 2px solid $secondary;
  color: $white;
  display: inline-block;
  cursor: pointer;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 20px;
}

.themebtn:hover {
  background-color: $white;
  color: $secondary;
}

.orange-whitebtn {
  background-color: $white;
  border: 2px solid $primary;
  color: $primary;
  display: inline-block;
  cursor: pointer;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 20px;
}

.orange-whitebtn:hover {
  background-color: $primary;
  color: $white;
}

.orange-whitebtn:focus-visible {
  outline: 0;
  border-color: #8fbdff;
}

.whitebtn:focus-visible {
  outline: 0;
  border-color: #8fbdff;
}
.overlay {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $gray-dark;
    opacity: 0.8;
  }

  &.primary {
    &:before {
      // background-color: #ae001573;
      background-color: $primary;
    }
  }
}

.orangebtn:focus-visible,
.orange-whitebtn:focus-visible,
.themebtn:focus-visible {
  outline: 0;
  border-color: #8fbdff;
}
.overlay-bhadarvi {
  &.primary {
    &:before {
      // background-color: #ae001573;
      background-color: rgb(174 0 21 / 91%);
    }
  }
}

.form-control {
  height: 40px;
  border: 1px solid $gray;
  // border-radius: 5px !important;
  color: $gray-dark;
  box-shadow: none;
}
.form-control:focus {
  border: 1px solid $gray;
  box-shadow: none;
}
.form-group {
  margin: 0 0 20px;

  label {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 5px;
  }

  .form-control {
    height: 40px;
    border: 1px solid $gray;
    // border-radius: 5px !important;
    color: $gray-dark;
    box-shadow: none;
  }

  textarea {
    height: 150px !important;
  }
}
.css-1xc3v61-indicatorContainer {
  padding: 10px !important;
}
.css-15lsz6c-indicatorContainer {
  padding: 10px !important;
}
.form-control [type='file'] {
  overflow: hidden;
  height: 40px;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  height: 45px;
}

.form-group select.form-control {
  background: url(../Assets/Images/Home_page/selecticon.png) no-repeat right
    15px center;
  background-size: 8px;
}

.donate-mobile {
  position: fixed;
  bottom: 60px;
  right: 22px;
  z-index: 888;
}

.custom-dropdown-menu {
  position: absolute;
  width: 180px;
  top: 200%;
  // opacity: 0;
  // display: none;
  transition: 0.3s all;
  right: 0;
  border-radius: 5px;
  transition: all 0.5s;
  border: none;
  background-color: $primary;
  border: 1px solid #8f1328;

  li {
    list-style: none;
    margin: 0;
    padding: 2px 0px;

    &:hover {
      background-color: transparent;
    }

    a {
      color: #ffffff !important;
      padding: 8px 15px;
      font-size: 15px;
      line-height: 24px;
      display: block;

      &:hover {
        color: $secondary !important;
        background-color: transparent;
      }
    }
  }
}

.custom-coming-soon {
  color: $primary !important;
}

@media only screen and (max-width: 991px) {
  h2 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  h2 {
    font-size: 24px;
  }

  h1 {
    font-size: 50px;
  }

  // section {
  //   padding: 25px 0;
  // }
}
