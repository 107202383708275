/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.red-text {
  color: red;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.white-text {
  color: #fff;
}

.btn:disabled {
  border: 2px solid #ae0015;
  border-radius: 30px;
  cursor: not-allowed;
}

input[type="checkbox"] {
  accent-color: #ae0015 !important;
}

.booking-confirmation .header-banner {
  /* background: url(assets/img/360_F_348195842_u4zUHo3BIygBoSItgHSrNGa6aUPLyRXi.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
}
.text-red {
  color: #ae0015;
}

body {
  user-select: none; /* Standard syntax */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* Internet Explorer/Edge */
  -moz-user-select: none; /* Firefox */
}
