@import "../../Css/variable.scss";

.gallery-page {
  margin: 4% 0%;
}

.photo-gallery {
  margin: 0px 0px 15px 0px;
  p {
    font-size: 16px;
    color: #fff;
    line-height: 33px;
    text-align: center;
    margin: 0px 0px;
    padding: 7px 0px;
    background: #ae0015;
    border-radius: 0px 0px 10px 10px;
  }
  .photo-img {
    overflow: hidden;
    position: relative;
    &:hover .photo-zoomicon {
      opacity: 1;
      visibility: visible;
    }
    > img {
      height: 100%;
      width: 100%;
      background-color: #ae0015;
      border-radius: 10px 10px 0px 0px;
    }
    .photo-zoomicon {
      background-color: #000000;
      height: 50px;
      width: 50px;
      display: inline-block;
      border-radius: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 10px;
      text-align: center;
      transition: 0.5s all;
      cursor: pointer;
      color: #ffffff;
      font-size: 18px;
      opacity: 0;
      visibility: visible;
    }
  }
}

.temple-image-modal {
  position: relative;
}

.temple-image-modal .img-container {
  position: relative;
}

.img-container p {
  position: absolute;
  bottom: 0px;
  color: #fff;
  padding: 12px 0px;
  margin: 0px;
  width: 100%;
  text-align: center;
  background-color: #00000063;
}
