@import "./variable.scss";
footer {
  padding: 50px 0 30px;
  background-color: #ae0015;
}
.footer-wrapper {
  padding: 0 0 20px;
  border-bottom: 1px solid #e5e3e3;
  font-size: 12px;
}
.footer-wrapper h5 {
  color: $white;
  margin: 0 0 15px;
}

.coman-social {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0px 0px 30px 0px;
}
.coman-social a {
  width: 26px;
  display: block;
}
.coman-social li:not(:last-child) {
  margin-right: 15px;
}
.coman-social .twiter a {
  background-color: #fff;
  border-radius: 50%;
  img {
    padding: 6px;
    object-fit: contain;
  }
}
.footer-info {
  // max-width: 280px;
  color: $white;
  margin-bottom: 15px;
  line-height: 21px;
}
.menu {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    margin-bottom: 12px;
    a {
      color: $white;
      &:hover {
        color: $secondary;
      }
    }
  }
}
.copyrightdiv {
  text-align: center;
  padding-top: 20px;
  font-size: 12px;
  color: $white;
  a {
    font-weight: bold;
    color: $white;
    &:hover {
      color: $secondary;
    }
  }
}
.location-info {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    margin-bottom: 12px;
    a {
      position: relative;
      padding-left: 30px;
      display: inline-block;
      color: $white;
      &:hover {
        color: $secondary;
      }
      i {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 18px;
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .footerbox {
    margin-bottom: 10px;
  }
  .footer-wrapper {
    // text-align: center;
    .coman-social {
      // justify-content: center;
      margin-bottom: 40px;
    }
    h5 {
      margin: 0 0 10px;
    }
    .menu {
      margin-bottom: 40px;
    }
  }
  .footer-info {
    margin: 0 auto 20px;
  }
  footer {
    // align-items: center;
    padding: 40px 0px 25px;
  }
  .copyrightdiv {
    text-align: left;
  }
}
