.seva-section {
  padding: 4% 0% 0% 0%;
}
.sevamain {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 30px;
  background-color: #ffffff;
}
.sevamain h3 {
  font-size: 25px;
  font-weight: bold;
  color: #ae0015;
  margin: 10px 0px;
  text-align: center;
}
.sevamain h4 {
  font-size: 17px;
  font-weight: bold;
  line-height: 26px;
  color: #000000;
  margin: 12px 0px;
  text-align: center;
}
.sevamain ol {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  margin-bottom: 0px;
}
.sevamain ol li {
  font-size: 15px;
  line-height: 30px;
  padding: 2px 0px;
  text-align: justify;
}
.sevamain .checkbox-boxes-sevamain {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
@media only screen and (max-device-width: 768px) {
  .sevamain {
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    padding: 15px;
  }

  .sevamain h3 {
    font-size: 17px;
    margin: 5px 0px;
  }
  .sevamain h4 {
    font-size: 15px;
    line-height: 25px;
    margin: 5px 0px;
  }
  .sevamain ol {
    padding-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
  }
  .sevamain ol li {
    font-size: 13px;
    line-height: 23px;
    padding: 4px 0px;
  }
  .sevamain label b {
    font-size: 13px;
  }
  .sevamain .checkbox-boxes-sevamain {
    width: 14x;
    height: 14px;
    margin-right: 5px;
    margin-top: 7px;
  }
}
