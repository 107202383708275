body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 0px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.goog-te-gadget-simple {
  line-height: 24px;
  background-color: #fff;
  border: 2px solid #ae0015 !important;
  border-radius: 30px;
  font-size: 16px;
  display: inline-block;
  padding: 6px 10px 6px !important;
  margin-top: 9px;
  margin-left: 5px;
  margin-right: 5px;
  float: right;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  /* padding-top: 1px; */
  /* padding-bottom: 2px; */
  cursor: pointer;
}

.goog-te-gadget-simple .VIpgJd-ZVi9od-xl07Ob-lTBxed span {
  text-decoration: none;
  color: #ae0015;
  font-weight: 600;
  font-size: 14px;
}

.goog-te-gadget-simple:hover {
  background-color: #ffd801;
  color: #fff;
}

.goog-te-gadget-icon {
  display: none;
}

.skiptranslate > iframe {
  display: none;
}

#goog-gt-tt {
  display: none !important;
}

@media only screen and (max-width: 980px) {
  .goog-te-gadget-simple .VIpgJd-ZVi9od-xl07Ob-lTBxed span {
    text-decoration: none;
    color: #ae0015;
    font-weight: 600;
    font-size: 12px;
    width: 50%;
  }
  .goog-te-gadget-simple {
    margin-right: 50px;
  }
}
